import { TFunction } from "i18next";
import { Attribute } from "../../models/templates/Attribute";
import { validateName, validateRequired } from "../Validators";

export const ValidateAttribute = (attribute: Attribute, t: TFunction) => {
  const errors: any = {};

  const groupIdResult = validateRequired(attribute.groupId, t("properties:group"));
  if (groupIdResult != null) {
    errors.groupId = groupIdResult;
  }

  const dataTypeResult = validateRequired(attribute.dataType, t("properties:type"));
  if (dataTypeResult != null) {
    errors.dataType = dataTypeResult;
  }

  const nameResult =
    validateRequired(attribute.name, t("properties:name")) ||
    validateName(attribute.name, t("properties:name"));
  if (nameResult != null) {
    errors.name = nameResult;
  }

  return errors;
};
