import { TFunction } from "i18next";
import { Language } from "../../models/reference_lists/Language";
import { validateName, validateRegEx, validateRequired } from "../Validators";

export const ValidateLanguage = (language: Language, t: TFunction) => {
  const errors: any = {};

  const nameResult =
    validateRequired(language.name, t("properties:name")) ||
    validateName(language.name, t("properties:name"));
  if (nameResult != null) {
    errors.name = nameResult;
  }

  const isoCodeResult =
    validateRequired(language.isoCode, t("properties:isoCode")) ||
    validateRegEx(
      language.isoCode,
      /^[a-zA-Z]{2}$/,
      t("validations:languageIsoCode", { property: t("properties:isoCode") }),
    );
  if (isoCodeResult != null) {
    errors.isoCode = isoCodeResult;
  }

  return errors;
};

export const defaultOrderedLanguages = [
  { order: 1, isoCode: "nl" },
  { order: 2, isoCode: "en" },
  { order: 3, isoCode: "de" },
  { order: 4, isoCode: "fr" },
  { order: 5, isoCode: "it" },
  { order: 6, isoCode: "es" },
  { order: 7, isoCode: "ru" },
  { order: 8, isoCode: "zh" },
];
