import { PlusOutlined } from "@ant-design/icons";
import { Button, Layout, Table, Tooltip } from "antd";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../hooks/useApi";
import { useAuthorization } from "../../hooks/useAuthorization";
import { Group } from "../../models/templates/Group";
import { RowButtonSet } from "../common/RowButtonSet";
import { GroupManagerDetail } from "./GroupManagerDetail";

export enum GroupType {
  Attribute = "Attribute",
  DocumentTemplate = "DocumentTemplate",
  Field = "Field",
  SecurityFeature = "SecurityFeature",
}

interface Props {
  visible: boolean;
  groupType: GroupType;
  onDataChanged?(dataIsChanged: boolean): void;
}

interface State {
  editModalVisible: boolean;
  editModalTitle: string;
  editingGroup: Group | null;
  newGroupName: string | null;
}

export const GroupManager = (props: Props) => {
  const [state, setState] = React.useState<State>({
    editModalVisible: false,
    editingGroup: null,
    editModalTitle: "",
    newGroupName: null,
  });

  const getEntityForAuthorization = () => {
    switch (props.groupType) {
      case GroupType.Attribute:
        return "attribute";
      case GroupType.Field:
        return "field";
      case GroupType.SecurityFeature:
        return "securityFeature";
      case GroupType.DocumentTemplate:
        return "documentTemplate";
    }
  };

  const { t } = useTranslation();
  const { canCreate, canUpdate, canDelete, canView } = useAuthorization(
    getEntityForAuthorization(),
  );
  const {
    data: groups,
    getAll: getGroups,
    get: getGroup,
    post: saveGroup,
    delete: deleteGroup,
    setCustomErrors,
  } = useApi<Group>(`${props.groupType}Groups`);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const createTableData = (groups: Group[]) => {
    if (groups.length === 0) {
      return null;
    } else {
      return groups.map((group) => ({
        key: group.id,
        name: group.name,
        children: createTableData(group.children ?? []),
      }));
    }
  };

  useEffect(() => {
    setCustomErrors({
      409: t("errors:duplicateGroup", { name: state.newGroupName }),
    });
  }, [state.newGroupName, setCustomErrors, t]);

  const addGroup = () => {
    const group = {} as Group;
    setState((prevState) => ({
      ...prevState,
      editingGroup: group,
      editModalVisible: true,
      editModalTitle: `${t("common:addGroup")}`,
    }));
  };

  const editGroup = async (id: string) => {
    const group = await getGroup(id);
    setState((prevState) => ({
      ...prevState,
      editingGroup: group,
      editModalVisible: true,
      editModalTitle: `${t("common:editGroup")}`,
    }));
  };

  const addChildGroup = async (id: string) => {
    const group = {} as Group;
    group.parentId = id;
    setState((prevState) => ({
      ...prevState,
      editingGroup: group,
      editModalVisible: true,
      editModalTitle: `${t("common:addGroup")}`,
    }));
  };

  const onSaveGroup = async (group: Group) => {
    setState((prevState) => ({
      ...prevState,
      newGroupName: group.name as string,
    }));
    const response = await saveGroup(group);
    if (response.isSuccess) {
      getGroups();
      setState((prevState) => ({
        ...prevState,
        editingGroup: null,
        editModalVisible: false,
      }));
      if (props.onDataChanged) {
        props.onDataChanged(true);
      }
    }
  };

  const onDeleteGroup = async (id: string) => {
    if (await deleteGroup(id)) {
      getGroups();
      setState((prevState) => ({
        ...prevState,
        editingGroup: null,
        editModalVisible: false,
      }));
      if (props.onDataChanged) {
        props.onDataChanged(true);
      }
    }
  };

  return (
    <Layout style={{ minWidth: "500px", background: "#fff" }}>
      <Table
        showHeader={true}
        dataSource={createTableData(groups)}
        columns={[
          { title: t("properties:name"), dataIndex: "name", key: "name" },
          {
            title: canCreate ? (
              <Tooltip title={t("common:add")}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  style={{ margin: "0px 2px" }}
                  size="small"
                  onClick={() => addGroup()}
                />
              </Tooltip>
            ) : null,
            dataIndex: "actions",
            align: "right",
            render: (text: any, record: any, index) => (
              <RowButtonSet
                onEdit={
                  canUpdate || canView
                    ? () => {
                        editGroup(record.key);
                      }
                    : null
                }
                onAddChild={
                  canCreate
                    ? () => {
                        addChildGroup(record.key);
                      }
                    : null
                }
                onDelete={
                  canDelete &&
                  (record.children == null ||
                    record.children === undefined ||
                    record.children.length === 0)
                    ? () => {
                        onDeleteGroup(record.key);
                      }
                    : null
                }
              />
            ),
            width: "120px",
          },
        ]}
        pagination={false}
        expandRowByClick={true}
        indentSize={25}
      />

      <GroupManagerDetail
        visible={state.editModalVisible}
        title={state.editModalTitle}
        group={state.editingGroup}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            editingGroup: null,
            editModalVisible: false,
          }))
        }
        onSave={(group) => onSaveGroup(group)}
      />
    </Layout>
  );
};
