import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DocumentSeries } from "../../models/data/Document";
import api from "../../services/api";
import { CONST_DOCUMENT_SERIES } from "../../utilities/constants/query-contants";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../hooks/useNotification";
import { useMutationBase } from "../useMutationBase";

export const getDocumentSerie = (id?: string): Promise<DocumentSeries> => {
  return api.get({
    path: `${CONST_DOCUMENT_SERIES.plural}/${id}`,
  });
};

export const useQueryDocumentSerie = (id?: string) => {
  return useQuery<DocumentSeries>(
    [CONST_DOCUMENT_SERIES.plural, { id }],
    () => getDocumentSerie(id),
    { enabled: !!id },
  );
};

export const postSaveDocumentserie = (payload: DocumentSeries) => {
  return api.post({
    path: CONST_DOCUMENT_SERIES.plural,
    body: {
      ...payload,
    },
  });
};

export const useSaveDocumentserie = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (payload: DocumentSeries) => postSaveDocumentserie(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([`${CONST_DOCUMENT_SERIES.plural}`]);
      queryClient.invalidateQueries([`${CONST_DOCUMENT_SERIES.plural}-list`]);

      notifySuccess(
        t("common:successSave", {
          entity: t("common:document").toLocaleLowerCase(),
        }),
      );
    },
  });
};

export const deleteDocumentSerie = (id: string) => {
  return api.delete({ path: `${CONST_DOCUMENT_SERIES.plural}/${id}` });
};

export const useDeleteDocumentSerie = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (id: string) => deleteDocumentSerie(id),
    onSuccess: () => {
      queryClient.invalidateQueries([`${CONST_DOCUMENT_SERIES.plural}`]);
      queryClient.invalidateQueries([`${CONST_DOCUMENT_SERIES.plural}-list`]);

      notifySuccess(
        t("common:successDelete", {
          entity: t("common:document").toLocaleLowerCase(),
        }),
      );
    },
  });
};
