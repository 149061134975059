import React, { Key } from "react";
import { Radio, Select, DatePicker, InputNumber, Input } from "antd";
import { useTranslation } from "react-i18next";
import { IListSchema } from "../../../models/InputComponentData";
import { Attribute, AttributeDataType } from "../../../models/templates/Attribute";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { CmsFormItem, CmsSelect } from "../../common/FormComponents";
import { useApi } from "../../../hooks/useApi";
import { Country } from "../../../models/reference_lists/Country";
import { Province } from "../../../models/reference_lists/Province";
import { Region } from "../../../models/reference_lists/Region";
import { Organization } from "../../../models/reference_lists/Organization";
import { capitalizeFirstLetter } from "../../../utilities/StringHelper";

interface Props {
  attr?: Attribute;
  dataType: AttributeDataType;
  defaultValue: any;
  updateDefaultValue(id: string, value: any): void;
  customList?: any;
}

export const AttributeFilterSchema = (props: Props) => {
  const { t } = useTranslation();

  const {
    getAll: getCountries,
    data: countries,
    isLoading: isLoadingCountries,
  } = useApi<Country>("countries");
  const {
    getAll: getProvinces,
    data: provinces,
    isLoading: isLoadingProvinces,
  } = useApi<Province>("provinces");
  const {
    getAll: getCurrencies,
    data: currencies,
    isLoading: isLoadingCurrencies,
  } = useApi<Country>("currencies");
  const {
    getAll: getRegions,
    data: regions,
    isLoading: isLoadingRegions,
  } = useApi<Region>("regions");
  const {
    getAll: getOrganizations,
    data: organizations,
    isLoading: isLoadingOrganizations,
  } = useApi<Organization>("organizations");

  React.useEffect(() => {
    if (props.dataType === AttributeDataType.Country) {
      getCountries();
    }
    if (props.dataType === AttributeDataType.Province) {
      getProvinces();
    }
    if (props.dataType === AttributeDataType.Region) {
      getRegions();
    }
    if (props.dataType === AttributeDataType.Organization) {
      getOrganizations();
    }
    if (props.dataType === AttributeDataType.Currency) {
      getCurrencies();
    }
  }, [getCountries, getProvinces, getRegions, getOrganizations, getCurrencies, props.dataType]);

  const generateInputs = () => {
    switch (props.dataType) {
      case AttributeDataType.Number:
        return generateFilterNumber();
      case AttributeDataType.DateTime:
        return generateFilterDateTime();
      case AttributeDataType.List:
        return generateFilterList();
      case AttributeDataType.Country:
        return generateCountryList();
      case AttributeDataType.Province:
        return generateProvinceList();
      case AttributeDataType.Region:
        return generateRegionList();
      case AttributeDataType.Currency:
        return generateCurrencyList();
      case AttributeDataType.Organization:
        return generateOrganizationList();
      case AttributeDataType.Text:
        return generateFilterText();
      case AttributeDataType.YesNo:
        return generateFilterYesNo();
      default:
        return null;
    }
  };

  const generateFilterYesNo = () => {
    return (
      <CmsFormItem
        label={capitalizeFirstLetter(
          getLocalizedValue(props.attr?.caption) || props.attr?.name || "",
        )}
      >
        <Radio.Group
          onChange={(e) =>
            props.attr?.id && props.updateDefaultValue(props.attr?.id, e.target.value)
          }
          value={props.defaultValue !== undefined ? props.defaultValue : undefined}
          buttonStyle="solid"
        >
          <Radio.Button value={undefined}>{"-"}</Radio.Button>
          <Radio.Button value={true}>{t("common:yes")}</Radio.Button>
          <Radio.Button value={false}>{t("common:no")}</Radio.Button>
        </Radio.Group>
      </CmsFormItem>
    );
  };

  const generateFilterList = () => {
    const listSchema = props.attr!.dataTypeSchema as IListSchema;
    return (
      <CmsSelect
        id="documentStatus"
        label={capitalizeFirstLetter(
          getLocalizedValue(props.attr?.caption) || props.attr?.name || "",
        )}
        placeholder={t("properties:makeSelection")}
        value={props.defaultValue !== undefined ? props.defaultValue : undefined}
        onChange={(e) => props.attr?.id && props.updateDefaultValue(props.attr?.id, e)}
      >
        {listSchema.items.map((x) => (
          <Select.Option key={x.code} value={x.code}>
            {getLocalizedValue(x.name) || x.code}
          </Select.Option>
        ))}
      </CmsSelect>
    );
  };

  const generateFilterDateTime = () => {
    return (
      <CmsFormItem
        label={capitalizeFirstLetter(
          getLocalizedValue(props.attr?.caption) || props.attr?.name || "",
        )}
      >
        <DatePicker
          value={props.defaultValue !== undefined ? props.defaultValue : undefined}
          placeholder={t("properties:date")}
          onChange={(e) => props.attr?.id && props.updateDefaultValue(props.attr?.id, e)}
        />
      </CmsFormItem>
    );
  };

  const generateCountryList = () => {
    return (
      <CmsSelect
        id="documentCountry"
        label={capitalizeFirstLetter(
          getLocalizedValue(props.attr?.caption) || props.attr?.name || "",
        )}
        placeholder={t("entities:country")}
        value={props.defaultValue !== undefined ? props.defaultValue : undefined}
        onChange={(e) => props.attr?.id && props.updateDefaultValue(props.attr?.id, e)}
      >
        {!isLoadingCountries &&
          countries &&
          countries.map((country) => (
            <Select.Option key={country.isoCode2} value={country.id as Key}>
              {getLocalizedValue(country.name)}
            </Select.Option>
          ))}
      </CmsSelect>
    );
  };

  const generateRegionList = () => {
    return (
      <CmsSelect
        id="documentRegion"
        label={capitalizeFirstLetter(
          getLocalizedValue(props.attr?.caption) || props.attr?.name || "",
        )}
        placeholder={t("entities:region")}
        value={props.defaultValue !== undefined ? props.defaultValue : undefined}
        onChange={(e) => props.attr?.id && props.updateDefaultValue(props.attr?.id, e)}
      >
        {!isLoadingRegions &&
          regions &&
          regions.map((region) => (
            <Select.Option key={region.isoCode} value={region.id as Key}>
              {getLocalizedValue(region.name)}
            </Select.Option>
          ))}
      </CmsSelect>
    );
  };

  const generateProvinceList = () => {
    return (
      <CmsSelect
        id="documentProvince"
        label={capitalizeFirstLetter(
          getLocalizedValue(props.attr?.caption) || props.attr?.name || "",
        )}
        placeholder={t("entities:province")}
        value={props.defaultValue !== undefined ? props.defaultValue : undefined}
        onChange={(e) => props.attr?.id && props.updateDefaultValue(props.attr?.id, e)}
      >
        {!isLoadingProvinces &&
          provinces &&
          provinces.map((province, index) => (
            <Select.Option key={`${province.name}_${index}`} value={province.id as Key}>
              {`${province.name} (${province.isoCode2})`}
            </Select.Option>
          ))}
      </CmsSelect>
    );
  };

  const generateFilterNumber = () => {
    return (
      <CmsFormItem
        label={capitalizeFirstLetter(
          getLocalizedValue(props.attr?.caption) || props.attr?.name || "",
        )}
      >
        <InputNumber
          maxLength={10}
          value={props.defaultValue}
          onChange={(e) => props.attr?.id && props.updateDefaultValue(props.attr?.id, e)}
          readOnly={false}
          disabled={false}
        />
      </CmsFormItem>
    );
  };

  const generateFilterText = () => {
    return (
      <CmsFormItem
        label={capitalizeFirstLetter(
          getLocalizedValue(props.attr?.caption) || props.attr?.name || "",
        )}
      >
        <Input
          value={props.defaultValue !== undefined ? props.defaultValue : undefined}
          allowClear
          onChange={(e) =>
            props.attr?.id &&
            props.updateDefaultValue(
              props.attr.id,
              e.target.value !== "" ? e.target.value : undefined,
            )
          }
          readOnly={false}
          disabled={false}
        />
      </CmsFormItem>
    );
  };

  const generateCurrencyList = () => {
    return (
      <CmsSelect
        id="documentCurrency"
        label={capitalizeFirstLetter(
          getLocalizedValue(props.attr?.caption) || props.attr?.name || "",
        )}
        placeholder={capitalizeFirstLetter(t("entities:currency"))}
        value={props.defaultValue !== undefined ? props.defaultValue : undefined}
        onChange={(e) => props.attr?.id && props.updateDefaultValue(props.attr.id, e)}
      >
        {!isLoadingCurrencies &&
          currencies &&
          currencies.map((currencie) => (
            <Select.Option key={currencie.id} value={currencie.id as Key}>
              {currencie.name}
            </Select.Option>
          ))}
      </CmsSelect>
    );
  };

  const generateOrganizationList = () => {
    return (
      <CmsSelect
        id="documentOrganization"
        label={capitalizeFirstLetter(
          getLocalizedValue(props.attr?.caption) || props.attr?.name || "",
        )}
        placeholder={capitalizeFirstLetter(t("entities:organization"))}
        value={props.defaultValue !== undefined ? props.defaultValue : undefined}
        onChange={(e) => props.attr?.id && props.updateDefaultValue(props.attr.id, e)}
      >
        {!isLoadingOrganizations &&
          organizations &&
          organizations.map((organization) => (
            <Select.Option key={organization.name} value={organization.id as Key}>
              {organization.name}
            </Select.Option>
          ))}
      </CmsSelect>
    );
  };

  return generateInputs();
};
