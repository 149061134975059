import { PrinterOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import * as _ from "lodash";
import { Printd } from "printd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../hooks/useApi";
import { AttributeValue } from "../../../models/data/AttributeValue";
import { SecurityFeatureSelection } from "../../../models/data/SecurityFeatureSelection";
import { View } from "../../../models/data/View";
import { Attribute } from "../../../models/templates/Attribute";
import { AttributeLink } from "../../../models/templates/AttributeLink";
import { SecurityFeature } from "../../../models/templates/SecurityFeature";
import { ViewTemplate } from "../../../models/templates/ViewTemplate";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { capitalizeFirstLetter } from "../../../utilities/StringHelper";
import blobToFile from "js-file-download";
import { CmsCancelButton, CmsDownloadButton } from "../../common/ButtonComponents";
import { CmsPageLoader } from "../../common/PageComponents";
import "./ScanOrder.scss";

interface Props {
  documentCode: string;
  documentId?: string;
  views: View[];
  viewTemplates: ViewTemplate[];
  onClose(): void;
}

interface State {
  securityFeatures: { [id: string]: SecurityFeature } | null;
  attributes: { [id: string]: Attribute } | null;
  isLoadingData: boolean;
}

export const ScanOrder = (props: Props) => {
  const { t } = useTranslation();

  const printComponent = new Printd();
  const printCss = ["/assets/css/scanorderprint.css"];

  const [state, setState] = useState<State>({
    securityFeatures: null,
    attributes: null,
    isLoadingData: true,
  });

  const { getMultiple: getSecurityFeatures } = useApi<SecurityFeature>("securityFeatures");
  const { getMultiple: getAttributes } = useApi<Attribute>("attributes");
  const { download, isLoading: isDownloading } = useApi<any>(`documentSeries`);

  useEffect(() => {
    (async () => {
      const securityFeatureIdsToFetch = props.views
        .flatMap((view) => {
          if (view.images) {
            return view.images.flatMap((image) => {
              if (image.securityFeatureSelections) {
                return image.securityFeatureSelections.map(
                  (selection) => selection.securityFeatureId,
                );
              }
              return [];
            });
          }
          return [];
        })
        .filter((id, index, array) => array.indexOf(id) === index);

      const securityFeatures = await getSecurityFeatures(securityFeatureIdsToFetch);

      const attributeIdsToFetch = Object.values(securityFeatures)
        .flatMap((securityFeature) =>
          securityFeature.attributeLinks.map(
            (attributeLink) => attributeLink.attributeId as string,
          ),
        )
        .filter((id, index, array) => array.indexOf(id) === index);

      const attributes = await getAttributes(attributeIdsToFetch);

      setState((prevState) => ({
        ...prevState,
        securityFeatures,
        attributes,
        isLoadingData: false,
      }));
    })();
  }, [props.views, getSecurityFeatures, getAttributes]);

  const getPageNumber = (view: View, viewTemplate: ViewTemplate) => {
    const pageAttributeLink = viewTemplate.attributeLinks.find(
      (link) => link.attributeName?.toLowerCase() === "page number",
    );
    if (pageAttributeLink) {
      const attributeValue = view.attributeValues?.find(
        (attributeValue) => attributeValue.attributeLinkId === pageAttributeLink.id,
      );
      return attributeValue ? attributeValue.value : "";
    }
  };

  const getAttributeNames = (
    attributeValues: AttributeValue[],
    attributeLinks: AttributeLink[],
  ) => {
    const attributeNames = [] as string[];
    _.orderBy(attributeLinks, "order", "asc").forEach((attributeLink) => {
      const attributeValue = attributeValues.find(
        (attributeValue) => attributeValue.attributeLinkId === attributeLink.id,
      );
      if (attributeValue && attributeValue.value === "true") {
        //eslint-disable-next-line
        const attribute = state.attributes?.[attributeLink.attributeId];
        if (attribute?.name) attributeNames.push(attribute.name);
      }
    });
    return attributeNames.join(", ");
  };

  const getImageCode = (
    viewTemplate: ViewTemplate,
    selection: SecurityFeatureSelection,
    selectionImageIndex: number,
  ) => {
    let imageCode =
      `${props.documentCode}_${capitalizeFirstLetter(viewTemplate.name?.toLowerCase() ?? "")}` +
      `_${capitalizeFirstLetter(
        state.securityFeatures?.[selection.securityFeatureId].code?.toLowerCase() ?? "",
      )}` +
      `_${selection.order + 1}`;

    if (selection.images && selection.images.length > 1) {
      imageCode += `_${String.fromCharCode(97 + selectionImageIndex).toUpperCase()}`;
    }
    return imageCode;
  };

  const renderRow = (
    rowKey: string,
    page: string,
    image: string,
    imagesCount: number,
    imageIndex: number,
    imageCode: string,
    remarks?: string,
  ) => {
    return (
      <tr key={`row_${rowKey}`}>
        <td>{page}</td>
        {imagesCount > 1 ? (
          imageIndex === 0 ? (
            <td rowSpan={imagesCount} className="multiRowColumn">
              {image}
            </td>
          ) : null
        ) : (
          <td>{image}</td>
        )}
        <td>&nbsp;</td>
        <td>{imageCode}</td>
        <td>&nbsp;</td>
        <td>{remarks}</td>
      </tr>
    );
  };

  const renderRows = (viewTemplate: ViewTemplate) => {
    const view = props.views.find((view) => view.viewTemplateId === viewTemplate.id);
    if (view) {
      return viewTemplate.imageTemplates.map((imageTemplate, imageTemplateIndex) => {
        const image = view.images?.find((image) => image.imageTemplateId === imageTemplate.id);
        if (image) {
          return renderRow(
            `${imageTemplateIndex}`,
            getPageNumber(view, viewTemplate) ?? "",
            `${capitalizeFirstLetter(
              getLocalizedValue(viewTemplate.caption) ?? "",
            )} (${imageTemplate.name})`,
            1,
            1,
            `${props.documentCode}_${capitalizeFirstLetter(
              viewTemplate.name?.toLowerCase() ?? "",
            )}_${imageTemplate.name}`,
          );
        }
        return null;
      });
    }
  };

  const renderSecurityFeatureRows = (viewTemplate: ViewTemplate, viewTemplateIndex: number) => {
    const view = props.views.find((view) => view.viewTemplateId === viewTemplate.id);

    if (view) {
      const mainImage = view.images?.find(
        (image) => image.securityFeatureSelections && image.securityFeatureSelections.length > 0,
      );

      if (mainImage) {
        const orderedSecurityFeatureSelections = _.sortBy(
          mainImage.securityFeatureSelections,
          (securityFeatureSelection) => {
            return `${
              state.securityFeatures?.[securityFeatureSelection.securityFeatureId].name
            } ${securityFeatureSelection.order}`;
          },
        ) as SecurityFeatureSelection[];

        return orderedSecurityFeatureSelections.map((selection, selectionIndex) => {
          if (selection.images) {
            return selection.images.map((_, selectionImageIndex) => {
              const attributeNames = getAttributeNames(
                selection.attributeValues ?? [],
                state.securityFeatures?.[selection.securityFeatureId].attributeLinks ?? [],
              );
              return renderRow(
                `${viewTemplateIndex}_${selectionIndex}_${selectionImageIndex}`,
                getPageNumber(view, viewTemplate) ?? "",
                `${
                  state.securityFeatures![selection.securityFeatureId].name
                } ${selection.order + 1}`,
                selection.images?.length ?? 0,
                selectionImageIndex,
                getImageCode(viewTemplate, selection, selectionImageIndex),
                attributeNames ? `${t("entities:Attributes")}: ${attributeNames}` : undefined,
              );
            });
          }
          return null;
        });
      }
    }
  };

  return (
    <Modal
      width={"60%"}
      title={t("common:scanOrder")}
      destroyOnClose={true}
      open={true}
      onCancel={props.onClose}
      style={{ paddingBottom: 0, maxWidth: "80vw" }}
      bodyStyle={{ maxHeight: "80vh", maxWidth: "80vw", overflow: "auto" }}
      centered={true}
      footer={[
        <CmsCancelButton key="cancel" onClick={props.onClose}>
          {t("common:cancel")}
        </CmsCancelButton>,
        <Button
          key="btnPrint"
          type="primary"
          icon={<PrinterOutlined />}
          style={{}}
          onClick={() =>
            printComponent.print(document.getElementById("mainDiv") as HTMLElement, printCss)
          }
        >
          {t("common:print")}
        </Button>,
        <CmsDownloadButton
          key="download"
          disabled={isDownloading}
          onClick={async () => {
            const file = await download(`${props.documentId as string}/scanorder`);
            if (!file) return;
            blobToFile(file, `${props.documentCode}.zip`);
          }}
        />,
      ]}
    >
      <React.Fragment>
        <div id="mainDiv">
          <h3>{props.documentCode}</h3>
          <div className="notes">{t("entities:notes")}</div>
          <br />
          <h4>{t("entities:images")}</h4>
          <CmsPageLoader loading={state.isLoadingData} subTitle={t("common:loadingData")}>
            {state.securityFeatures && state.attributes ? (
              <table className="scanOrder">
                <tbody>
                  <tr>
                    <th>{t("common:page")}</th>
                    <th>{t("common:image")}</th>
                    <th>{t("common:from")}</th>
                    <th>{t("common:imageCode")}</th>
                    <th>{t("common:done")}</th>
                    <th>{t("common:remarks")}</th>
                  </tr>
                  {props.viewTemplates.map((viewTemplate) => renderRows(viewTemplate))}
                  <tr>
                    <td colSpan={6} className="titleRow">
                      <h4>
                        {t("entities:securityFeature")} {t("entities:images").toLowerCase()}
                      </h4>
                    </td>
                  </tr>
                  <tr>
                    <th>{t("common:page")}</th>
                    <th>{t("common:image")}</th>
                    <th>{t("common:from")}</th>
                    <th>{t("common:imageCode")}</th>
                    <th>{t("common:done")}</th>
                    <th>{t("common:remarks")}</th>
                  </tr>
                  {props.viewTemplates.map((viewTemplate, index) =>
                    renderSecurityFeatureRows(viewTemplate, index),
                  )}
                </tbody>
              </table>
            ) : null}
          </CmsPageLoader>
        </div>
      </React.Fragment>
    </Modal>
  );
};
