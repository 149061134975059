import { Breadcrumb } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useCmsContext } from "../context/app/CmsContext";
import { BreadcrumbComponent } from "../context/app/context-types";
import { DocumentTemplateEntity } from "../models/templates/DocumentTemplate";

export const CmsBreadcrumb = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const match = useRouteMatch({
    path: ["/document-series/:documentTemplateId/:id", "/document-series/:documentTemplateId"],
    exact: true,
    strict: true,
  });

  const context = useCmsContext();
  const [state, setState] = useState<{
    component?: BreadcrumbComponent;
  }>({
    component: undefined,
  });

  const components = useMemo(
    () => [
      {
        name: t("entities:attributes"),
        forRoutes: ["attributes", "attribute"],
        routeTo: "/attributes",
      },
      {
        name: t("entities:countries"),
        forRoutes: ["countries", "country"],
        routeTo: "/countries",
      },
      {
        name: t("entities:currencies"),
        forRoutes: ["currencies", "currency"],
        routeTo: "/currencies",
      },
      {
        name: t("entities:documentTemplates"),
        forRoutes: ["document-templates", "document-template"],
        routeTo: "/document-templates",
      },
      {
        name: t("entities:documentTypes"),
        forRoutes: ["document-types", "document-type"],
        routeTo: "/document-types",
      },
      {
        name: t("entities:documentSeries"),
        forRoutes: ["document-series", "document-wizard"],
        routeTo: `/document-series/${(match?.params as DocumentTemplateEntity)?.documentTemplateId}`,
      },
      {
        name: t("entities:fields"),
        forRoutes: ["fields", "field"],
        routeTo: "/fields",
      },
      {
        name: t("entities:languages"),
        forRoutes: ["languages", "language"],
        routeTo: "/languages",
      },
      {
        name: t("entities:materialTypes"),
        forRoutes: ["material-types", "material-type"],
        routeTo: "/material-types",
      },
      {
        name: t("entities:organizations"),
        forRoutes: ["organizations", "organization"],
        routeTo: "/organizations",
      },
      {
        name: t("entities:regions"),
        forRoutes: ["regions", "region"],
        routeTo: "/regions",
      },
      {
        name: t("entities:securityFeatures"),
        forRoutes: ["security-features", "security-feature"],
        routeTo: "/security-features",
      },
      {
        name: t("common:translations"),
        forRoutes: ["translations"],
        routeTo: "/translations",
      },
      {
        name: t("common:imageUpload"),
        forRoutes: ["image-upload"],
        routeTo: "/image-upload",
      },
    ],
    [t],
  );

  useEffect(() => {
    context?.setBreadcrumbItems([]);

    const segments = pathname.split("/");
    const component =
      segments.length > 1
        ? components.find((c) => c.forRoutes.indexOf(segments[1].toLowerCase()) > -1)
        : undefined;

    setState((prevState) => ({ ...prevState, component }));
  }, [pathname, components]);

  const items = [] as { title: string; href?: string }[];

  if (state.component) {
    items.push({
      title: state.component.name,
      href: state.component.routeTo,
    });
  }

  context?.breadcrumbItems.forEach((item) => {
    items.push({
      title: item.name,
    });
  });

  return (
    <Breadcrumb
      style={{ marginBottom: "16px" }}
      separator={"/"}
      items={[{ title: "Home", href: "/" }, ...items]}
    />
  );
};
