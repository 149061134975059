import { LoadingOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Input, Radio, Select, Typography } from "antd";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../../hooks/useApi";
import { DocumentTemplate } from "../../../../models/templates/DocumentTemplate";
import styles from "./DocumentWizard.module.scss";
const { Title } = Typography;

export enum CreationMode {
  NewDocument = 0,
  CopyDocument = 1,
}

interface DocumentSearchResult {
  id: string;
  code: string;
}

interface Props {
  documentTemplates: DocumentTemplate[];
  documentTemplateId?: string;
  documentId?: string;
  documentCode?: string;
  creationMode: CreationMode;
  onNext: (creationMode: CreationMode, documentTemplateId?: string, documentId?: string) => void;
}

interface State {
  creationMode: CreationMode;
  documentTemplateId?: string;
  documentId?: string;
  documentSearchText?: string;
  documentSearchResults: DocumentSearchResult[];
}

export const StepTemplate = (props: Props) => {
  const [state, setState] = useState<State>({
    creationMode: props.creationMode,
    documentTemplateId: props.documentTemplateId,
    documentId: props.documentId,
    documentSearchText: props.documentCode,
    documentSearchResults: [],
  });

  const { post: postSearch, isLoading } = useApi<DocumentSearchResult>(`search`);

  const { t } = useTranslation();
  const timer = useRef<NodeJS.Timer | null>(null);

  const searchDocuments = () => {
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(async () => {
      if (state.documentSearchText && state.documentSearchText?.length > 0) {
        const searchResults = await postSearch({
          query: `code: ${state.documentSearchText}*`,
        });

        if (searchResults.isSuccess) {
          setState((prevState) => ({
            ...prevState,
            documentSearchResults: searchResults.data,
          }));
        }
      } else {
        setState((prevState) => ({ ...prevState, documentSearchResults: [] }));
      }
    }, 500);
  };

  return (
    <React.Fragment>
      <div className={`${styles["step-content"]} ${styles["step-template"]}`}>
        <Title level={3}>{t("common:template")}</Title>
        <div className={styles["step-text"]}>{t("texts:newDocumentStep1")}</div>
        <Radio.Group
          className={styles["creation-mode"]}
          value={state.creationMode}
          onChange={(event) =>
            setState((prevState) => ({
              ...prevState,
              creationMode: event.target.value,
              documentId: undefined,
              documentTemplateId: undefined,
            }))
          }
        >
          <Radio value={CreationMode.NewDocument}>{t("texts:newDocumentStep1NewDocument")}</Radio>
          <Radio value={CreationMode.CopyDocument}>{t("texts:newDocumentStep1CopyDocument")}</Radio>
        </Radio.Group>
        {state.creationMode === CreationMode.NewDocument && (
          <React.Fragment>
            <div>{t("texts:newDocumentStep1ChooseDocumentTemplate")}:</div>
            <Select
              style={{ width: "350px" }}
              value={state.documentTemplateId}
              onChange={(documentTemplateId) =>
                setState((prevState) => ({
                  ...prevState,
                  documentTemplateId: documentTemplateId,
                }))
              }
            >
              {props.documentTemplates.map((documentTemplate, index) => (
                <Select.Option key={index} value={documentTemplate.id ?? ""}>
                  {documentTemplate.name}
                </Select.Option>
              ))}
            </Select>
          </React.Fragment>
        )}
        {state.creationMode === CreationMode.CopyDocument && (
          <React.Fragment>
            <div>Zoek een document:</div>
            <AutoComplete
              placeholder={t("common:search")}
              options={state.documentSearchResults.map((document) => ({
                value: document.code,
                key: document.id,
              }))}
              value={state.documentSearchText}
              onKeyUp={() => searchDocuments()}
              onChange={(value) =>
                setState((prevState) => ({
                  ...prevState,
                  documentSearchText: value,
                }))
              }
              onSelect={(_, item) =>
                setState((prevState) => ({
                  ...prevState,
                  documentId: item.key?.toString(),
                  documentSearchResults: [],
                }))
              }
            >
              <Input
                suffix={
                  isLoading ? (
                    <LoadingOutlined className="certain-category-icon" spin />
                  ) : (
                    <SearchOutlined className="certain-category-icon" />
                  )
                }
              />
            </AutoComplete>
          </React.Fragment>
        )}
      </div>
      <div className={styles["button-bar"]}>
        <Button
          className={"button-next"}
          type="primary"
          icon={<RightOutlined />}
          disabled={
            !(
              (state.creationMode === CreationMode.NewDocument && state.documentTemplateId) ||
              (state.creationMode === CreationMode.CopyDocument && state.documentId)
            )
          }
          onClick={() => {
            props.onNext(state.creationMode, state.documentTemplateId, state.documentId);
          }}
        >
          {t("common:next")}
        </Button>
      </div>
    </React.Fragment>
  );
};
