import { UploadOutlined } from "@ant-design/icons";
import { Affix, Checkbox, Col, InputNumber, Layout, Row, Tabs } from "antd";
import * as _ from "lodash";
import Dragger from "antd/lib/upload/Dragger";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import config from "../../../Config";
import { useApi } from "../../../hooks/useApi";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { DocumentSeries } from "../../../models/data/Document";
import { ImageTemplate } from "../../../models/templates/ImageTemplate";
import { ConvertCrop } from "../../../utilities/CropHelper";
import { CmsButton, CmsDeleteButton, CmsUploadButton } from "../../common/ButtonComponents";
import { CmsForm, CmsFormItem, CmsText } from "../../common/FormComponents";
import { CmsContent, CmsPageLoader, CmsTabs } from "../../common/PageComponents";
import { AddSecurityFeatureModal } from "./AddSecurityFeatureModal";
import { DocumentsImageInstruction } from "./DocumentsImageInstructions";
import ReactLassoSelect from "react-lasso-select";
import { FieldList } from "./FieldList";
import "./ImageDetails.scss";
import { SecurityFeatureList } from "./SecurityFeatureList";
import { Point } from "react-lasso-select/lib/helpers";
import { ConvertPolygon } from "../../../utilities/PolygonHelper";
import VideoManagement from "./VideoManagement";
import { Video } from "../../../models/data/Video";
import { SecurityFeatureSelection } from "../../../models/data/SecurityFeatureSelection";
import { generateSecurityFeatureSelectionPlaceholder } from "../../../utilities/SecurityFeatureSelectionHelper";
import { Image } from "../../../models/data/Image";
import { RcFile } from "antd/lib/upload/interface";

const { TabPane } = Tabs;
const { Sider } = Layout;

interface Props {
  documentTemplateId: string;
  imageTemplate: ImageTemplate;
  viewIndex: number;
  imageIndex: number;
}

interface State {
  showAddSecurityFeatureModal: boolean;
  activeImageTabKey: string;
  isDragOver: boolean;
  copyInstructionsModalVisible: boolean;
  isLoadingImage?: boolean;
  shapeType?: "RECTANGLE" | "POLYGON";
  crop: Crop | Partial<Crop>;
  points?: Point[];
}

export const ImageDetails = (props: Props) => {
  const { canUpdate } = useAuthorization("document");
  const { t } = useTranslation();
  const { values, setFieldValue, submitForm } = useFormikContext<DocumentSeries>();

  const image = values.views[props.viewIndex].images?.[props.imageIndex] as Image;

  const [imageSize, setImageSize] = useState<{
    width: number;
    height: number;
    natWidth: number;
    natHeight: number;
  }>({ width: 0, height: 0, natWidth: 0, natHeight: 0 });

  const [state, setState] = useState<State>({
    showAddSecurityFeatureModal: false,
    activeImageTabKey: "securityFeatures",
    isDragOver: false,
    copyInstructionsModalVisible: false,
    shapeType: undefined,
    crop: {},
    points: undefined,
  });

  const { post: saveFile, isLoading } = useApi<File>("file");
  const { post: saveVideo, delete: deleteVideo } = useApi<Video>("videos");

  useEffect(() => {
    const onDragEnter = () => {
      if (!state.isDragOver) {
        setState((prevState) => ({ ...prevState, isDragOver: true }));
      }
    };

    const onDragLeave = (event) => {
      if (event.screenX === 0 && event.screenY === 0) {
        setState((prevState) => ({ ...prevState, isDragOver: false }));
      }
    };

    window.addEventListener("dragenter", onDragEnter);
    window.addEventListener("dragleave", onDragLeave);
    window.addEventListener(
      "dragover",
      (e) => {
        e.preventDefault();
      },
      false,
    );
    window.addEventListener(
      "drop",
      (e) => {
        e.preventDefault();
      },
      false,
    );

    return () => {
      window.removeEventListener("dragenter", onDragEnter);
      window.removeEventListener("dragleave", onDragLeave);
      window.removeEventListener("dragover", onDragLeave);
      window.removeEventListener("drop", onDragLeave);
    };
  }, [state.isDragOver]);

  const onAddVideo = async (file: string | Blob | RcFile, index: number) => {
    const response = await saveFile(file, { postAsFile: true });

    if (response.isSuccess) {
      const uploadVideo = await saveVideo({
        fileId: response.data.id,
        filename: response.data.fileName,
        imageId: image?.id,
        entityId: values.id,
      } as Video);

      if (uploadVideo.isSuccess) {
        setFieldValue(`views[${props.viewIndex}].images[${props.imageIndex}].videos[${index}]`, {
          id: uploadVideo.data.id,
          fileId: response.data.id,
          filename: response.data.fileName,
          imageId: image?.id,
          entityId: values.id,
        } as Video);
      }
    }
  };

  const onRemoveVideo = async (id: string, videos: Array<Video>) => {
    setFieldValue(`views[${props.viewIndex}].images[${props.imageIndex}].videos`, videos);
    await deleteVideo(id);
  };

  const onShowRegion = (shapeType: "RECTANGLE" | "POLYGON", crop: Crop, points?: Point[]) => {
    if (shapeType === "RECTANGLE") {
      setState((prevState) => ({
        ...prevState,
        shapeType: shapeType,
        crop: ConvertCrop(crop.width as number, crop.height as number, crop, "ABSOLUTE", imageSize),
        points: undefined,
      }));
    }

    if (shapeType === "POLYGON" && image?.fileId && points !== null && points !== undefined) {
      setState((prevState) => ({
        ...prevState,
        shapeType: shapeType,
        crop: {},
        points: ConvertPolygon(points, "ABSOLUTE", imageSize),
      }));
    }
  };

  const onClearRegion = () => {
    setState((prevState) => ({
      ...prevState,
      shapeType: undefined,
      crop: {},
      points: undefined,
    }));
  };

  const uploadFile = async (file: string | Blob | RcFile, fileId: string) => {
    setState((prevState) => ({ ...prevState, isLoadingImage: true }));

    const response = await saveFile(file, { path: fileId, postAsFile: true });

    if (response.isSuccess && response.data.id !== fileId) {
      setFieldValue(
        `views[${props.viewIndex}].images[${props.imageIndex}].fileId`,
        response.data.id,
      );

      await submitForm();
    }

    setState((prevState) => ({ ...prevState, isLoadingImage: false }));
  };

  const [anotherSelection, setAnotherSelection] = useState<{
    securityFeatureSelection: SecurityFeatureSelection;
    selectedSecurityFeatureId: string;
    preGeneratedImagesCount?: number;
  }>();
  const handleSecurityFeatureAdd = async (
    securityFeatureSelection: SecurityFeatureSelection,
    selectedSecurityFeatureId: string,
    preGeneratedImagesCount?: number,
  ) => {
    const allDocumentSecurityFeatureSelections = _.flatMap(values.views, (view) =>
      _.flatMap(view.images, (image) => image.securityFeatureSelections),
    ) as SecurityFeatureSelection[];

    const sameSecurityFeatures = allDocumentSecurityFeatureSelections.filter(
      (securityFeatureSelection) =>
        securityFeatureSelection &&
        securityFeatureSelection.securityFeatureId === selectedSecurityFeatureId,
    );

    securityFeatureSelection.order =
      sameSecurityFeatures.length > 0
        ? _.maxBy(sameSecurityFeatures, (securityFeature) => securityFeature.order).order + 1
        : 0;

    if (preGeneratedImagesCount) {
      for (let i = 0; i < preGeneratedImagesCount; i++) {
        securityFeatureSelection.images = generateSecurityFeatureSelectionPlaceholder(
          securityFeatureSelection.images ?? [],
        );
      }
    }

    const selections =
      values.views[props.viewIndex].images?.[props.imageIndex].securityFeatureSelections?.slice(0);

    selections?.push(securityFeatureSelection);

    setFieldValue(
      `views[${props.viewIndex}].images[${props.imageIndex}].securityFeatureSelections`,
      selections,
    );

    await submitForm();
    setAnotherSelection({
      securityFeatureSelection: {
        ...securityFeatureSelection,
        images: undefined,
      },
      selectedSecurityFeatureId,
      preGeneratedImagesCount,
    });
  };

  return (
    <React.Fragment>
      <CmsPageLoader loading={isLoading} subTitle={t("common:loadingData")}>
        <Layout>
          <Sider
            width={"auto"}
            style={{
              overflow: "hidden",
              background: "#fff",
              paddingRight: "16px",
            }}
          >
            {canUpdate && (
              <Row gutter={[5, 5]}>
                <Col>
                  <CmsUploadButton
                    accept="image/*"
                    customRequest={(options) => {
                      uploadFile(options.file, image?.fileId as string);
                    }}
                  />
                </Col>
                <Col>
                  {image?.fileId && (
                    <CmsDeleteButton
                      onConfirm={(e) => {
                        e?.stopPropagation();
                        setFieldValue(
                          `views[${props.viewIndex}].images[${props.imageIndex}].fileId`,
                          null,
                        );
                      }}
                    />
                  )}
                </Col>
                <Col>
                  <>
                    <CmsButton
                      buttonType="add"
                      style={{ marginBottom: "12px", marginRight: "5px" }}
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          showAddSecurityFeatureModal: true,
                        }));
                      }}
                    >
                      {t("common:addSecurityFeature")}
                    </CmsButton>
                    <CmsButton
                      buttonType="add"
                      type="default"
                      disabled={!anotherSelection}
                      onClick={async () => {
                        if (anotherSelection) {
                          await handleSecurityFeatureAdd(
                            anotherSelection.securityFeatureSelection,
                            anotherSelection.selectedSecurityFeatureId,
                            anotherSelection.preGeneratedImagesCount,
                          );
                        }
                      }}
                      title={t("common:anotherOneDescription")}
                    >
                      {t("common:anotherOne")}
                    </CmsButton>
                  </>
                </Col>
              </Row>
            )}

            {!isLoading && (
              <Row>
                <Affix offsetTop={15}>
                  <Dragger
                    className="drag-container"
                    accept={"image/*"}
                    multiple={false}
                    customRequest={(options) => {
                      setState((prevState) => ({
                        ...prevState,
                        isDragOver: false,
                      }));
                      uploadFile(options.file, image?.fileId as string);
                    }}
                  >
                    {image?.fileId ? (
                      <div style={{ width: "425px" }}>
                        {state.shapeType === "POLYGON" && (
                          <ReactLassoSelect
                            src={`${config.apiUrl}file/${image.fileId}?imageSize=M`}
                            value={state.points}
                            imageStyle={{
                              width: "425px",
                            }}
                            onImageLoad={({ currentTarget }) => {
                              setImageSize({
                                width: currentTarget.width,
                                height: currentTarget.height,
                                natWidth: currentTarget.naturalWidth,
                                natHeight: currentTarget.naturalHeight,
                              });
                            }}
                          />
                        )}
                        {(state.shapeType === undefined || state.shapeType === "RECTANGLE") && (
                          <ReactCrop
                            src={`${config.apiUrl}file/${image.fileId}?imageSize=M`}
                            crop={state.crop}
                            onChange={() => undefined}
                            locked={true}
                            onImageLoaded={(t) =>
                              setImageSize({
                                width: t.width,
                                height: t.height,
                                natWidth: t.naturalWidth,
                                natHeight: t.naturalHeight,
                              })
                            }
                            imageStyle={{
                              width: "425px",
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      <img src="/assets/images/keesing-logo.jpg" alt="Keesing logo" />
                    )}

                    {state.isDragOver && (
                      <div className="drag-overlay">
                        <div className="drag-icon-and-text">
                          <div className="drag-icon">
                            <UploadOutlined />
                          </div>
                          <div className="drag-text">Choose a file or drag it here</div>
                        </div>
                      </div>
                    )}
                  </Dragger>
                </Affix>
              </Row>
            )}

            {state.showAddSecurityFeatureModal && (
              <AddSecurityFeatureModal
                onAdd={async (
                  securityFeatureSelection,
                  selectedSecurityFeatureId,
                  preGeneratedImagesCount,
                ) => {
                  setState((prevState) => ({
                    ...prevState,
                    showAddSecurityFeatureModal: false,
                  }));

                  await handleSecurityFeatureAdd(
                    securityFeatureSelection,
                    selectedSecurityFeatureId,
                    preGeneratedImagesCount,
                  );
                }}
                onClose={() =>
                  setState((prevState) => ({
                    ...prevState,
                    showAddSecurityFeatureModal: false,
                  }))
                }
              />
            )}
          </Sider>
          <CmsContent>
            <CmsTabs
              activeKey={state.activeImageTabKey}
              onChange={(activeKey) => {
                setState((prevState) => ({
                  ...prevState,
                  activeImageTabKey: activeKey,
                }));
              }}
            >
              <TabPane tab={t("entities:securityFeatures")} key="securityFeatures">
                <SecurityFeatureList
                  image={image as Image}
                  securityFeatureSelections={image?.securityFeatureSelections ?? []}
                  onShowRegion={onShowRegion}
                  onClearRegion={onClearRegion}
                  onChange={async (securityFeatureSelections) => {
                    setFieldValue(
                      `views[${props.viewIndex}].images[${props.imageIndex}].securityFeatureSelections`,
                      securityFeatureSelections,
                    );

                    await submitForm();
                  }}
                />
              </TabPane>
              <TabPane tab={t("entities:attributes")} key="attributes">
                <CmsForm>
                  <CmsText
                    readOnly={!canUpdate}
                    key="sourceInfo"
                    label={t("properties:source")}
                    placeholder={t("properties:source")}
                    maxLength={50}
                    value={image?.sourceInfo}
                    onChange={(e) =>
                      setFieldValue(
                        `views[${props.viewIndex}].images[${props.imageIndex}].sourceInfo`,
                        e.target.value,
                      )
                    }
                  />
                  <CmsFormItem label={t("properties:dpi")}>
                    <InputNumber
                      readOnly={!canUpdate}
                      key="dpi"
                      placeholder={t("properties:dpi")}
                      value={image?.dpi}
                      onChange={(value) =>
                        setFieldValue(
                          `views[${props.viewIndex}].images[${props.imageIndex}].dpi`,
                          value,
                        )
                      }
                    />
                  </CmsFormItem>

                  <CmsFormItem label={t("properties:exportable")}>
                    <Checkbox
                      disabled={!canUpdate}
                      key="exportable"
                      checked={image?.exportable ?? true}
                      onChange={(e) =>
                        setFieldValue(
                          `views[${props.viewIndex}].images[${props.imageIndex}].exportable`,
                          e.target.checked,
                        )
                      }
                    />
                  </CmsFormItem>
                </CmsForm>
              </TabPane>
              {props.imageTemplate.fieldLinks.length > 0 && (
                <TabPane tab={t("entities:fields")} key="fields">
                  <FieldList
                    fileId={image?.fileId as string}
                    imageId={image?.id as string}
                    fieldLinks={props.imageTemplate.fieldLinks}
                    fieldValues={image?.fieldValues ?? []}
                    documentTemplateId={props.documentTemplateId}
                    viewTemplateId={values.views[props.viewIndex].viewTemplateId}
                    imageTemplateId={image?.imageTemplateId as string}
                    onShowRegion={onShowRegion}
                    onClearRegion={onClearRegion}
                    onChange={(fieldValues) => {
                      setFieldValue(
                        `views[${props.viewIndex}].images[${props.imageIndex}].fieldValues`,
                        fieldValues,
                      );
                    }}
                    onSaveDocument={async () => await submitForm()}
                  />
                </TabPane>
              )}
              <TabPane tab={t("common:instructions")} key="instructions" disabled={!canUpdate}>
                <DocumentsImageInstruction
                  viewIndex={props.viewIndex}
                  imageIndex={props.imageIndex}
                />
              </TabPane>
              <TabPane tab={t("common:videos")} key="videos">
                <VideoManagement
                  videos={image?.videos ?? []}
                  onAdd={onAddVideo}
                  onRemove={onRemoveVideo}
                />
              </TabPane>
            </CmsTabs>
          </CmsContent>
        </Layout>
      </CmsPageLoader>
    </React.Fragment>
  );
};
