import { useQuery } from "@tanstack/react-query";
import { Language } from "../../models/reference_lists/Language";
import api from "../../services/api";
import { CONST_LANGUAGES } from "../../utilities/constants/query-contants";
import { OrderedLanguages } from "./types";
import { defaultOrderedLanguages } from "../../utilities/helpers/language-helper";

export const getLanguages = (): Promise<Language[]> => {
  return api.get({
    path: CONST_LANGUAGES.plural,
  });
};

export const useQueryLanguages = () => {
  return useQuery<Language[]>([CONST_LANGUAGES.plural], () => getLanguages());
};

export const useQueryOrderedLanguages = () => {
  let order = defaultOrderedLanguages.length + 1;

  return useQuery<Language[], unknown, OrderedLanguages[]>(
    [CONST_LANGUAGES.plural],
    () => getLanguages(),
    {
      select: (languages) =>
        languages
          .map((language) => {
            const matchingDefaultOrderedLanguage = defaultOrderedLanguages.find(
              (item) => item.isoCode === language.isoCode,
            );

            return {
              order: matchingDefaultOrderedLanguage
                ? matchingDefaultOrderedLanguage.order
                : order++,
              isoCode: language.isoCode as string,
              name: language.name as string,
            };
          })
          .sort((curr, next) => (curr.order > next.order ? 1 : -1)),
    },
  );
};
