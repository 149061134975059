import { CmsFormLayout } from "../../components/common/FormComponents";
import { DocumentStatus } from "../../models/data/Document";
import { Attribute, AttributeDataType } from "../../models/templates/Attribute";
import { getLocalizedValue } from "../MultilingualHelper";
import { IListSchema } from "../../models/InputComponentData";

const sanatizeString = (value: string, key: string, replacement?: string) =>
  value.split(key).join(replacement ?? "");

export const filterOptionsConfig = {
  product: {
    indexKey: "documentTemplateId",
    type: "list",
  },
  region: {
    indexKey: "issuedByRegionId",
    type: "list",
  },
  country: {
    indexKey: "issuedByCountryId",
    type: "list",
  },
  state: {
    indexKey: "issuedByProvinceId",
    type: "list",
  },
  documentType: {
    indexKey: "documentTypeId",
    type: "list",
  },
  circulationStatus: {
    indexKey: "inCirculation",
    type: "list",
    values: [
      { name: "In circulation", value: "true" },
      { name: "Out circulation", value: "false" },
    ],
  },
  primaryPhotoTechnique: {
    indexKey: "primaryPhotoTechnique",
    type: "list",
  },
  textualPersonalisation: {
    indexKey: "textualPersonalisation",
    type: "list",
  },
  seriesYear: {
    indexKey: "series",
    type: "input",
  },
  materialType: {
    indexKey: "materialTypeId",
    type: "list",
  },
  hasManipulatedImages: {
    indexKey: "hasManipulatedImages",
    type: "list",
    values: [
      { name: "Yes", value: "true" },
      { name: "No", value: "false" },
    ],
  },
  acquisitionSource: {
    indexKey: "acquisitionSource",
    type: "input",
  },
  commemorativeNote: {
    indexKey: "commemorativeNote",
    type: "list",
    values: [
      { name: "Yes", value: "true" },
      { name: "No", value: "false" },
    ],
  },
  collectorsItem: {
    indexKey: "collectorsItem",
    type: "list",
    values: [
      { name: "Yes", value: "true" },
      { name: "No", value: "false" },
    ],
  },
  documentStatus: {
    indexKey: "status",
    type: "list",
    values: [
      {
        name: "New",
        value: sanatizeString(DocumentStatus.New.toString(), "_"),
      },
      {
        name: "Being edited",
        value: sanatizeString(DocumentStatus.EditedEditorial.toString(), "_"),
      },
      {
        name: "Pre press",
        value: sanatizeString(DocumentStatus.EditedStudio.toString(), "_"),
      },
      {
        name: "Ready but do not publish",
        value: sanatizeString(DocumentStatus.PostponedForPublishing.toString(), "_"),
      },
      {
        name: "Ready for publishing",
        value: sanatizeString(DocumentStatus.ReadyForPublishing.toString(), "_"),
      },
      {
        name: "Not for publishing",
        value: sanatizeString(DocumentStatus.NotForPublishing.toString(), "_"),
      },
      {
        name: "Wanted",
        value: sanatizeString(DocumentStatus.Wanted.toString(), "_"),
      },
      {
        name: "Acquisition",
        value: sanatizeString(DocumentStatus.Acquisition.toString(), "_"),
      },
    ],
  },
  inVault: {
    indexKey: "isInVault",
    type: "list",
    values: [
      { name: "Yes", value: "true" },
      { name: "No", value: "false" },
    ],
  },
  worldRegion: {
    indexKey: "worldRegionId",
    type: "list",
  },
} as Record<filterOptionKey, OptionSettings>;

export type OptionSettings = {
  indexKey: string;
  type: "list" | "input" | "checkbox" | "date";
  values?: FilterOption[];
};

export type SearchSetting = {
  id?: string;
  name: string;
  searchString: string;
  fields: string;
};

export type FilterOption = {
  name?: string;
  value?: string;
};

export type filterOptionKey =
  | "product"
  | "region"
  | "country"
  | "state"
  | "documentType"
  | "circulationStatus"
  | "primaryPhotoTechnique"
  | "textualPersonalisation"
  | "seriesYear"
  | "materialType"
  | "hasManipulatedImages"
  | "acquisitionSource"
  | "collectorsItem"
  | "commemorativeNote"
  | "securityFeatures"
  | "documentStatus"
  | "inVault"
  | "worldRegion";

export const getDocumentStatisticsConfig = (key: filterOptionKey) => filterOptionsConfig[key];

export const getQueryByKey = (key: filterOptionKey, value: any) =>
  `${filterOptionsConfig[key].indexKey}: ${value}`;

export const getAttributeDataList = (key: string, attributes?: Attribute[]): FilterOption[] => {
  const attribute = attributes?.find((attribute) => attribute.name === key);

  if (attribute && attribute.dataType === AttributeDataType.List) {
    return (attribute.dataTypeSchema as IListSchema).items.map((item) => ({
      name: getLocalizedValue(item.name),
      value: item.code,
    }));
  }

  return [{}];
};

export const getAttributeCaption = (key: string, attributes?: Attribute[]): string =>
  getLocalizedValue(
    attributes?.find((attribute) => attribute.name?.toLocaleLowerCase() === key.toLocaleLowerCase())
      ?.caption,
  ) ?? "";

export const timeStampFormat = "YYYY-MM-DD hh:mm:ss:SSS";
export const dateTimeFormat = "YYYY-MM-DD hh:mm";
export const dateFormat = "YYYY-MM-DD";
export const timeFormat = "hh:mm";

export const emptyFilters = {
  product: undefined,
  region: undefined,
  country: undefined,
  state: undefined,
  documentType: undefined,
  circulationStatus: undefined,
  primaryPhotoTechnique: undefined,
  textualPersonalization: undefined,
  seriesYear: undefined,
  materialType: undefined,
  hasManipulatedImages: undefined,
  acquisitionSource: undefined,
  collectorsItem: undefined,
  commemorativeNote: undefined,
  images: undefined,
  securityFeatures: undefined,
  startDate: undefined,
  endDate: undefined,
  documentStatus: undefined,
  inVault: undefined,
  worldRegion: undefined,
};

export const emtpySearchSetting = {
  modal: false,
  data: undefined,
  isLoading: false,
};

export const emtpyDateFilter = { start: undefined, end: undefined };

export const formLayout = CmsFormLayout.twocolumn;
