import { LeftOutlined, LoadingOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Divider, Modal, Radio, Result, Select, Steps, Table, Typography } from "antd";
import { useFormikContext } from "formik";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../hooks/useApi";
import { DocumentSeries } from "../../../models/data/Document";
import { Instruction } from "../../../models/data/Instruction";
import { DocumentTemplate } from "../../../models/templates/DocumentTemplate";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { capitalizeFirstLetter } from "../../../utilities/StringHelper";
import { CmsButton } from "../../common/ButtonComponents";
import { MultilingualInput } from "../../common/MultilingualInput";
import { CmsSearch } from "../../common/PageComponents";
import styles from "./CopyModal.module.scss";
const { Title } = Typography;
const { Option } = Select;

enum WizardStepsDocument {
  ChooseDocument = 0,
  ChooseTexts = 1,
  ChooseLanguages = 2,
  Options = 3,
  Summary = 4,
  ChooseView,
  ChooseImage,
}

enum WizardStepsImages {
  ChooseDocument = 0,
  ChooseView = 1,
  ChooseImage = 2,
  ChooseTexts = 3,
  ChooseLanguages = 4,
  Options = 5,
  Summary = 6,
}

interface MappedInstruction {
  from: string;
  to: string;
}

interface ExtendedInstruction extends Instruction {
  documentCode: string;
}

interface Props {
  onImport(instructions: Instruction[]);
  instructionType: string;
  viewIndex?: number;
  imageIndex?: number;
  onClose(): void;
}

interface State {
  currentStep: number;
  currentDocumentInstructions: Instruction[];
  documents: Array<DocumentSeries>;
  filteredDocuments?: Array<DocumentSeries>;
  documentSearchString?: string;
  searchType: "KEESINGCODE" | "INSTRUCTIONS";
  filteredInstructions: Array<ExtendedInstruction>;
  selectedDocumentId?: string;
  selectedDocument?: DocumentSeries;
  selectedViewId?: string;
  selectedImageId?: string;
  selectedInstructionIds: string[];
  selectedLanguages: string[];
  selectedDocumentInstructions: Instruction[];
  writingType: "APPEND" | "PARTIAL" | "OVERWRITE" | "APPEND_TEXT";
  mappedInstructions: MappedInstruction[];
}

export const CopyInstructionsModal = (props: Props) => {
  const WizardSteps =
    props.instructionType === "imageInstructions" ? WizardStepsImages : WizardStepsDocument;

  const [state, setState] = useState<State>({
    currentStep: WizardSteps.ChooseDocument,
    currentDocumentInstructions: [],
    documents: [],
    filteredDocuments: [],
    documentSearchString: undefined,
    searchType: "KEESINGCODE",
    filteredInstructions: [],
    selectedDocumentId: undefined,
    selectedDocument: undefined,
    selectedViewId: undefined,
    selectedImageId: undefined,
    selectedInstructionIds: [],
    selectedLanguages: [],
    selectedDocumentInstructions: [],
    writingType: "APPEND",
    mappedInstructions: [],
  });

  const { values: document } = useFormikContext<DocumentSeries>();

  const {
    get: getDocument,
    getAll: getAllDocuments,
    isLoading: isLoadingDocuments,
  } = useApi<DocumentSeries>("documentSeries");

  const { post: postSearch } = useApi<DocumentSeries[]>("search");

  const { get: getDocumentTemplate, isLoading: isLoadingDocumentTemplate } =
    useApi<DocumentTemplate>("documentTemplates");

  const [documentTemplate, setDocumentTemplate] = useState<DocumentTemplate>();

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (document.documentTemplateId) {
        const documents = await getAllDocuments({
          querystringParams: {
            DocumentTemplateId: document.documentTemplateId,
          },
        });

        const filteredDocument = documents.filter((docu) => docu.id !== document.id);

        setState((prevState) => ({
          ...prevState,
          documents: filteredDocument,
          filteredDocuments: filteredDocument,
        }));
      }
    })();

    const instruction =
      props.instructionType === "imageInstructions" &&
      props.imageIndex !== undefined &&
      props.viewIndex !== undefined
        ? document.views[props.viewIndex].images?.[props.imageIndex]?.instructions
        : document.instructions;

    if (instruction) {
      setState((prevState) => ({
        ...prevState,
        currentDocumentInstructions: instruction,
      }));
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      state.currentStep === WizardSteps.ChooseView ||
      state.currentStep === WizardSteps.ChooseTexts
    ) {
      (async () => {
        if (state.selectedDocumentId && state.selectedDocumentId !== state.selectedDocument?.id) {
          const selectedDocument = await getDocument(state.selectedDocumentId);

          setState((prevState) => ({
            ...prevState,
            selectedDocument,
            selectedViewId: undefined,
            selectedImageId: undefined,
            selectedInstructionIds: [],
            selectedLanguages: [],
            overwrite: false,
          }));
        }
      })();
    }
  }, [
    state.currentStep,
    state.selectedDocument,
    state.selectedDocumentId,
    WizardSteps,
    getDocument,
  ]);

  useEffect(() => {
    (async () => {
      if (state.selectedDocument?.documentTemplateId) {
        const template = await getDocumentTemplate(state.selectedDocument.documentTemplateId);
        setDocumentTemplate(template);
      }
    })();
  }, [getDocumentTemplate, state.selectedDocument]);

  const multiLanguageEmptyCheck = (instruction: Instruction): boolean => {
    return (
      instruction.caption !== null &&
      Object.keys(instruction.caption).length !== 0 &&
      instruction.caption["en"] !== undefined &&
      instruction.caption["en"] !== ""
    );
  };

  const search = async (text: string) => {
    if (!isLoadingDocuments && state.documents) {
      let filteredDocuments = state.documents;
      let filteredInstructions = [] as ExtendedInstruction[];

      setState((prevState) => ({
        ...prevState,
        selectedDocumentId: undefined,
      }));

      if (state.searchType === "INSTRUCTIONS") {
        if (!text) {
          filteredInstructions = [];
        } else {
          const query = text.split("%").filter((term) => term !== "");

          state.documents.forEach((document) => {
            document.instructions?.forEach((instruction) => {
              // eslint-disable-next-line
              Object.values(instruction.text).find((value) => {
                if (
                  query.every((term) =>
                    value.toLocaleLowerCase().includes(term.toLocaleLowerCase()),
                  ) &&
                  document.code &&
                  multiLanguageEmptyCheck(instruction)
                ) {
                  filteredInstructions.push({
                    ...instruction,
                    documentCode: document.code,
                  });

                  return instruction;
                }
              });
            });
          });
        }
      }

      if (state.searchType === "KEESINGCODE") {
        const search = text.toLowerCase().replace(" ", "_");
        filteredDocuments = state.documents.filter((document) => {
          const name = document.code && document.code.toLowerCase();

          return name && name.includes(search);
        });

        if (filteredDocuments.length === 0) {
          const searchResult = await postSearch({
            query: search,
          });

          if (searchResult.isSuccess) {
            filteredDocuments = searchResult.data;
          }
        }

        filteredInstructions = [];
      }

      setState((prevState) => ({
        ...prevState,
        documentSearchString: text,
        filteredDocuments: filteredDocuments,
        filteredInstructions: filteredInstructions,
      }));
    }
  };

  const setInstructionMapping = (from: string, to: string): void => {
    const mappedInstruction = {
      from: from,
      to: to,
    } as MappedInstruction;

    const fromIndex = state.mappedInstructions.findIndex(
      (mappedInstruction) => mappedInstruction.from === from,
    );

    const mappedInstructions = state.mappedInstructions;
    if (fromIndex !== -1) {
      mappedInstructions[fromIndex] = mappedInstruction;
    } else {
      mappedInstructions.push(mappedInstruction);
    }

    setState((prevState) => ({
      ...prevState,
      mappedInstructions: mappedInstructions,
    }));
  };

  const getViewTemplateId = () => {
    if (documentTemplate && state.selectedViewId && state.selectedDocument) {
      return state.selectedDocument.views.find((view) => view.id === state.selectedViewId)
        ?.viewTemplateId;
    }
    return "";
  };

  const viewTemplateName = (viewTemplateId?: string) => {
    if (viewTemplateId && documentTemplate && documentTemplate.viewTemplates) {
      const viewTemplate = documentTemplate.viewTemplates.find(
        (viewTemplate) => viewTemplate.id === viewTemplateId,
      );

      if (viewTemplate) {
        return getLocalizedValue(viewTemplate.caption) || viewTemplate.name;
      }
    }
    return "";
  };

  const imageTemplateName = (imageTemplateId?: string) => {
    if (imageTemplateId && documentTemplate && state.selectedViewId) {
      const imageTemplate = documentTemplate.viewTemplates
        ?.find((viewTemplate) => viewTemplate.id === getViewTemplateId())
        ?.imageTemplates.find((image) => image.id === imageTemplateId);

      if (imageTemplate) {
        return getLocalizedValue(imageTemplate.caption) || imageTemplate.name;
      }
    }
    return "";
  };

  const getLanguageTranslation = (languageCode: string) => {
    switch (languageCode) {
      case "en":
        return t("common:english");
      case "es":
        return t("common:spanish");
      case "de":
        return t("common:german");
      case "fr":
        return t("common:french");
      case "it":
        return t("common:italian");
      case "nl":
        return t("common:dutch");
      case "ru":
        return t("common:russian");
      case "zh":
        return t("common:chinese");
      default:
        return "";
    }
  };

  const selectedInstructions = () => {
    if (!state.selectedDocument) return;

    const getInstructions =
      props.instructionType === "imageInstructions"
        ? state.selectedDocument.views
            ?.find((viewId) => viewId.id === state.selectedViewId)
            ?.images?.find((imageId) => imageId.id === state.selectedImageId)?.instructions
        : state.selectedDocument.instructions;

    if (!getInstructions) return;

    const instructions = getInstructions.filter(
      (instruction) => instruction.id && state.selectedInstructionIds.includes(instruction.id),
    );

    setState((prevState) => ({
      ...prevState,
      selectedDocumentInstructions: instructions,
    }));
  };

  const importInstructions = () => {
    const selection = state.selectedDocumentInstructions.map((instruction) => ({
      caption: _.pick(instruction.caption, state.selectedLanguages),
      text: _.pick(instruction.text, state.selectedLanguages),
      order: instruction.order,
    }));

    let modifiedInstructions =
      (props.instructionType === "imageInstructions" &&
      props.imageIndex !== undefined &&
      props.viewIndex !== undefined
        ? document.views[props.viewIndex].images?.[props.imageIndex]?.instructions
        : document.instructions) ?? [];

    switch (state.writingType) {
      case "APPEND":
        modifiedInstructions = modifiedInstructions.concat(selection);
        break;
      case "PARTIAL":
        modifiedInstructions = modifiedInstructions.map((instruction) => {
          const fromIndex = state.mappedInstructions.findIndex(
            (mappedInstruction) => mappedInstruction.to === instruction.id,
          );

          if (fromIndex !== -1) {
            return {
              ...instruction,
              caption: selection[fromIndex].caption,
              text: selection[fromIndex].text,
            } as Instruction;
          }

          return instruction;
        });
        break;
      case "OVERWRITE":
        modifiedInstructions = selection;
        break;
      case "APPEND_TEXT":
        modifiedInstructions = modifiedInstructions.map((instruction) => {
          const fromIndex = state.mappedInstructions.findIndex(
            (mappedInstruction) => mappedInstruction.to === instruction.id,
          );

          if (fromIndex !== -1) {
            const currentText = instruction.text;

            Object.entries(selection[fromIndex].text).forEach(([key, value]) => {
              if (currentText[key]) {
                currentText[key] += `\n\n====================\n\n${value}`;
              }
            });

            return {
              ...instruction,
              text: currentText,
            } as Instruction;
          }

          return instruction;
        });
        break;
      default:
        break;
    }

    props.onImport(modifiedInstructions);
  };

  return (
    <Modal
      width={1000}
      title={t("common:copyInstructions")}
      open={true}
      closable={true}
      onCancel={props.onClose}
      footer={null}
    >
      <div className={styles["wizard"]}>
        <Steps current={state.currentStep} direction="vertical" className={styles["stepper"]}>
          <Steps.Step title={t("common:source")} />
          {props.instructionType === "imageInstructions" && (
            <Steps.Step title={t("common:views")} />
          )}
          {props.instructionType === "imageInstructions" && (
            <Steps.Step title={t("common:image")} />
          )}
          <Steps.Step title={t("common:texts")} />
          <Steps.Step title={t("common:languages")} />
          <Steps.Step title={t("common:options")} />
          <Steps.Step title={t("common:summary")} />
        </Steps>
        <div className={styles["step"]}>
          {state.currentStep === WizardSteps.ChooseDocument ? (
            <React.Fragment>
              <div className={styles["step-content"]}>
                <Title level={3}>{t("common:source")}</Title>
                <div className={styles["step-text"]}>{t("texts:copyInstructionsStep1")}</div>
                {!isLoadingDocuments ? (
                  <React.Fragment>
                    <div className={styles["search"]}>
                      <CmsButton
                        buttonType="default"
                        type={state.searchType === "KEESINGCODE" ? "primary" : "default"}
                        style={{ marginRight: "5px" }}
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            searchType: "KEESINGCODE",
                            selectedDocumentId: undefined,
                          }))
                        }
                      >
                        Keesingcode
                      </CmsButton>
                      <CmsButton
                        buttonType="default"
                        type={state.searchType === "INSTRUCTIONS" ? "primary" : "default"}
                        style={{ marginRight: "5px" }}
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            searchType: "INSTRUCTIONS",
                            selectedDocumentId: undefined,
                          }))
                        }
                      >
                        Instruction
                      </CmsButton>
                      <CmsSearch
                        key="search"
                        value={state.documentSearchString}
                        onChange={search}
                      />
                    </div>
                    {state.searchType === "KEESINGCODE" && (
                      <Table
                        bordered
                        size={"small"}
                        dataSource={state.filteredDocuments}
                        rowKey="id"
                        rowSelection={{
                          type: "radio",
                          selectedRowKeys: state.selectedDocumentId
                            ? [state.selectedDocumentId]
                            : [],
                          onSelect: (document) =>
                            setState((prevState) => ({
                              ...prevState,
                              selectedDocumentId: document.id,
                            })),
                        }}
                        pagination={{ pageSize: 6 }}
                        onRow={(document) => ({
                          onClick: () =>
                            setState((prevState) => ({
                              ...prevState,
                              selectedDocumentId: document.id,
                            })),
                        })}
                        columns={[
                          {
                            title: t("common:document"),
                            dataIndex: "code",
                            onFilter: () => {
                              return true;
                            },
                          },
                        ]}
                      />
                    )}
                    {state.searchType === "INSTRUCTIONS" && (
                      <>
                        <Divider orientation="left" style={{ fontSize: "14px", fontWeight: 400 }}>
                          {t("common:copyModalInstructionSearch")}
                        </Divider>

                        <Table
                          bordered
                          size={"small"}
                          dataSource={state.filteredInstructions}
                          rowKey="id"
                          rowSelection={{
                            type: "radio",
                            selectedRowKeys: state.selectedDocumentId
                              ? [
                                  state.filteredInstructions.find(
                                    (instruction) =>
                                      instruction.documentId === state.selectedDocumentId,
                                  )?.id || "",
                                ]
                              : [],
                            onSelect: (document) =>
                              setState((prevState) => ({
                                ...prevState,
                                selectedDocumentId: document.documentId,
                              })),
                          }}
                          pagination={{ pageSize: 6 }}
                          onRow={(document) => ({
                            onClick: () =>
                              setState((prevState) => ({
                                ...prevState,
                                selectedDocumentId: document.documentId,
                              })),
                          })}
                          columns={[
                            {
                              title: t("common:document"),
                              dataIndex: "documentCode",
                              onFilter: () => {
                                return true;
                              },
                            },
                            {
                              title: t("common:instruction"),
                              render: (_, instruction) => (
                                <React.Fragment>
                                  <MultilingualInput
                                    key={`caption_${instruction.id}`}
                                    mlData={instruction.caption}
                                    readOnly={true}
                                  />
                                  <div className={styles["text"]}>
                                    <MultilingualInput
                                      key={`text_${instruction.id}`}
                                      mlData={instruction.text}
                                      readOnly={true}
                                      multiLine={true}
                                      hideTabs={true}
                                    />
                                  </div>
                                </React.Fragment>
                              ),
                            },
                          ]}
                        />
                      </>
                    )}
                  </React.Fragment>
                ) : (
                  <Result icon={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                )}
              </div>

              <div className={styles["button-bar"]}>
                <Button
                  className={"button-next"}
                  type="primary"
                  icon={<RightOutlined />}
                  disabled={!state.selectedDocumentId}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      currentStep:
                        props.instructionType === "imageInstructions"
                          ? WizardSteps.ChooseView
                          : WizardSteps.ChooseTexts,
                    }))
                  }
                >
                  {t("common:next")}
                </Button>
              </div>
            </React.Fragment>
          ) : null}

          {props.instructionType === "imageInstructions" &&
          state.currentStep === WizardSteps.ChooseView ? (
            <React.Fragment>
              <div className={styles["step-content"]}>
                <Title level={3}>{t("common:views")}</Title>
                <div className={styles["step-text"]}>{t("texts:copyInstructionsStepView")}</div>
                {state.selectedDocument?.views && !isLoadingDocumentTemplate ? (
                  <Table
                    bordered
                    size={"small"}
                    dataSource={state.selectedDocument.views}
                    rowKey="id"
                    rowSelection={{
                      type: "radio",
                      selectedRowKeys: state.selectedViewId ? [state.selectedViewId] : [],
                      onSelect: (view) =>
                        setState((prevState) => ({
                          ...prevState,
                          selectedDocumentId: view.id,
                        })),
                    }}
                    pagination={{ pageSize: 6 }}
                    onRow={(view) => ({
                      onClick: () =>
                        setState((prevState) => ({
                          ...prevState,
                          selectedViewId: view.id,
                        })),
                    })}
                    columns={[
                      {
                        title: t("common:views"),
                        render: (_, instruction) => (
                          <React.Fragment>
                            {capitalizeFirstLetter(
                              viewTemplateName(instruction?.viewTemplateId) || "",
                            )}
                          </React.Fragment>
                        ),
                      },
                    ]}
                  />
                ) : (
                  <Result icon={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                )}
              </div>
              <div className={styles["button-bar"]}>
                <Button
                  type="primary"
                  icon={<LeftOutlined />}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      currentStep: WizardSteps.ChooseDocument,
                      selectedViewId: undefined,
                    }))
                  }
                >
                  {t("common:previous")}
                </Button>
                <Button
                  className={"button-next"}
                  type="primary"
                  icon={<RightOutlined />}
                  disabled={!state.selectedViewId}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      currentStep: WizardSteps.ChooseImage,
                    }))
                  }
                >
                  {t("common:next")}
                </Button>
              </div>
            </React.Fragment>
          ) : null}

          {props.instructionType === "imageInstructions" &&
          state.currentStep === WizardSteps.ChooseImage ? (
            <React.Fragment>
              <div className={styles["step-content"]}>
                <Title level={3}>{t("common:image")}</Title>
                <div className={styles["step-text"]}>{t("texts:copyInstructionsStepImage")}</div>
                {state.selectedViewId && !isLoadingDocumentTemplate && documentTemplate ? (
                  <Table
                    bordered
                    size={"small"}
                    dataSource={
                      state.selectedDocument?.views.find(
                        (viewId) => viewId.id === state.selectedViewId,
                      )?.images
                    }
                    rowKey="id"
                    rowSelection={{
                      type: "radio",
                      selectedRowKeys: state.selectedImageId ? [state.selectedImageId] : [],
                      onSelect: (image) =>
                        setState((prevState) => ({
                          ...prevState,
                          selectedDocumentId: image.id,
                        })),
                    }}
                    pagination={{ pageSize: 6 }}
                    onRow={(image) => ({
                      onClick: () =>
                        setState((prevState) => ({
                          ...prevState,
                          selectedImageId: image.id,
                        })),
                    })}
                    columns={[
                      {
                        title: t("common:image"),
                        render: (_, instruction) => (
                          <React.Fragment>
                            {capitalizeFirstLetter(
                              imageTemplateName(instruction.imageTemplateId) || "",
                            )}
                          </React.Fragment>
                        ),
                      },
                    ]}
                  />
                ) : (
                  <Result icon={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                )}
              </div>
              <div className={styles["button-bar"]}>
                <Button
                  type="primary"
                  icon={<LeftOutlined />}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      currentStep: WizardSteps.ChooseView,
                      selectedImageId: undefined,
                    }))
                  }
                >
                  {t("common:previous")}
                </Button>
                <Button
                  className={"button-next"}
                  type="primary"
                  icon={<RightOutlined />}
                  disabled={!state.selectedImageId}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      currentStep: WizardSteps.ChooseTexts,
                    }))
                  }
                >
                  {t("common:next")}
                </Button>
              </div>
            </React.Fragment>
          ) : null}

          {state.currentStep === WizardSteps.ChooseTexts ? (
            <React.Fragment>
              <div className={styles["step-content"]}>
                <Title level={3}>{t("common:texts")}</Title>
                <div className={styles["step-text"]}>{t("texts:copyInstructionsStep2")}</div>
                {state.selectedDocument && !isLoadingDocuments ? (
                  <Table
                    bordered
                    size={"small"}
                    dataSource={
                      props.instructionType === "imageInstructions"
                        ? state.selectedDocument.views
                            .find((viewId) => viewId.id === state.selectedViewId)
                            ?.images?.find((imageId) => imageId.id === state.selectedImageId)
                            ?.instructions
                        : state.selectedDocument.instructions
                    }
                    rowKey="id"
                    rowSelection={{
                      type: "checkbox",
                      selectedRowKeys: state.selectedInstructionIds,
                      onChange: (selectedRowKeys) =>
                        setState((prevState) => ({
                          ...prevState,
                          selectedInstructionIds: selectedRowKeys as string[],
                          mappedInstructions: [],
                        })),
                    }}
                    pagination={false}
                    columns={[
                      {
                        title: t("common:instruction"),
                        render: (value, instruction) => (
                          <React.Fragment>
                            <MultilingualInput
                              key={`caption_${instruction.id}`}
                              mlData={instruction.caption}
                              readOnly={true}
                            />
                            <div className={styles["text"]}>
                              <MultilingualInput
                                key={`text_${instruction.id}`}
                                mlData={instruction.text}
                                readOnly={true}
                                multiLine={true}
                                hideTabs={true}
                              />
                            </div>
                          </React.Fragment>
                        ),
                      },
                    ]}
                  />
                ) : (
                  <Result icon={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                )}
              </div>
              <div className={styles["button-bar"]}>
                <Button
                  type="primary"
                  icon={<LeftOutlined />}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      currentStep:
                        props.instructionType === "imageInstructions"
                          ? WizardSteps.ChooseImage
                          : WizardSteps.ChooseDocument,
                    }))
                  }
                >
                  {t("common:previous")}
                </Button>
                <Button
                  className={"button-next"}
                  type="primary"
                  icon={<RightOutlined />}
                  disabled={state.selectedInstructionIds.length === 0}
                  onClick={() => {
                    selectedInstructions();
                    setState((prevState) => ({
                      ...prevState,
                      currentStep: WizardSteps.ChooseLanguages,
                    }));
                  }}
                >
                  {t("common:next")}
                </Button>
              </div>
            </React.Fragment>
          ) : null}
          {state.currentStep === WizardSteps.ChooseLanguages ? (
            <React.Fragment>
              <div className={styles["step-content"]}>
                <Title level={3}>{t("common:languages")}</Title>
                <div className={styles["step-text"]}>{t("texts:copyInstructionsStep3")}</div>
                <Table
                  bordered
                  size={"small"}
                  dataSource={[
                    { id: "nl", name: t("common:dutch") },
                    { id: "en", name: t("common:english") },
                    { id: "de", name: t("common:german") },
                    { id: "fr", name: t("common:french") },
                    { id: "it", name: t("common:italian") },
                    { id: "es", name: t("common:spanish") },
                    { id: "ru", name: t("common:russian") },
                    { id: "zh", name: t("common:chinese") },
                  ]}
                  rowKey="id"
                  rowSelection={{
                    type: "checkbox",
                    selectedRowKeys: state.selectedLanguages,
                    onChange: (selectedLanguages) =>
                      setState((prevState) => ({
                        ...prevState,
                        selectedLanguages: selectedLanguages as string[],
                      })),
                  }}
                  pagination={false}
                  columns={[
                    {
                      title: t("entities:language"),
                      render: (_, language) => <React.Fragment>{language.name}</React.Fragment>,
                    },
                  ]}
                />
              </div>
              <div className={styles["button-bar"]}>
                <Button
                  type="primary"
                  icon={<LeftOutlined />}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      currentStep: WizardSteps.ChooseTexts,
                    }))
                  }
                >
                  {t("common:previous")}
                </Button>
                <Button
                  className={"button-next"}
                  type="primary"
                  icon={<RightOutlined />}
                  disabled={state.selectedLanguages.length === 0}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      currentStep: WizardSteps.Options,
                    }))
                  }
                >
                  {t("common:next")}
                </Button>
              </div>
            </React.Fragment>
          ) : null}
          {state.currentStep === WizardSteps.Options ? (
            <React.Fragment>
              <div className={styles["step-content"]}>
                <Title level={3}>{t("common:options")}</Title>
                <div className={styles["step-text"]}>{t("texts:copyInstructionsStep4")}</div>
                <Radio.Group
                  onChange={(e) => {
                    setState((prevState) => ({
                      ...prevState,
                      writingType: e.target.value,
                    }));
                  }}
                  value={state.writingType}
                >
                  <Radio value={"APPEND"} style={{ display: "block" }}>
                    {t(`texts:copyInstructionsSummaryAPPEND`)}
                  </Radio>
                  <Radio
                    value={"APPEND_TEXT"}
                    style={{ display: "block" }}
                    disabled={
                      state.currentDocumentInstructions.length === 0 ||
                      state.currentDocumentInstructions.filter((instruction) =>
                        multiLanguageEmptyCheck(instruction),
                      ).length === 0
                    }
                  >
                    {t(`texts:copyInstructionsSummaryAPPEND_TEXT`)}
                  </Radio>
                  <Radio
                    value={"PARTIAL"}
                    style={{ display: "block" }}
                    disabled={
                      state.currentDocumentInstructions.length === 0 ||
                      state.currentDocumentInstructions.filter((instruction) =>
                        multiLanguageEmptyCheck(instruction),
                      ).length === 0
                    }
                  >
                    {t(`texts:copyInstructionsSummaryPARTIAL`)}
                  </Radio>
                  <Radio value={"OVERWRITE"} style={{ display: "block" }}>
                    {t(`texts:copyInstructionsSummaryOVERWRITE`)}
                  </Radio>
                </Radio.Group>
                {state.writingType === "APPEND_TEXT" && (
                  <Table
                    bordered
                    size={"small"}
                    dataSource={state.selectedDocumentInstructions}
                    rowKey="id"
                    style={{ paddingTop: "20px" }}
                    pagination={false}
                    columns={[
                      {
                        title: capitalizeFirstLetter(t("entities:instruction")),
                        render: (_, instruction) => (
                          <React.Fragment>{instruction.caption["en"]}</React.Fragment>
                        ),
                      },
                      {
                        title: capitalizeFirstLetter(t("entities:appendsTo")),
                        render: (overwriteInstructions: Instruction) => (
                          <React.Fragment>
                            <Select
                              style={{ width: 180 }}
                              onChange={(value) => {
                                overwriteInstructions.id &&
                                  setInstructionMapping(overwriteInstructions.id, value);
                              }}
                              defaultValue={
                                state.mappedInstructions.find(
                                  (mappedInstruction) =>
                                    mappedInstruction.from === overwriteInstructions.id,
                                )?.to
                              }
                            >
                              {state.currentDocumentInstructions
                                .filter((di) => multiLanguageEmptyCheck(di))
                                .map(
                                  (instruction) =>
                                    instruction.id && (
                                      <Option value={instruction.id}>
                                        {instruction.caption["en"]}
                                      </Option>
                                    ),
                                )}
                            </Select>
                          </React.Fragment>
                        ),
                      },
                    ]}
                  />
                )}
                {state.writingType === "PARTIAL" && (
                  <Table
                    bordered
                    size={"small"}
                    dataSource={state.selectedDocumentInstructions}
                    rowKey="id"
                    style={{ paddingTop: "20px" }}
                    pagination={false}
                    columns={[
                      {
                        title: capitalizeFirstLetter(t("entities:instruction")),
                        render: (_, instruction) => (
                          <React.Fragment>{instruction.caption["en"]}</React.Fragment>
                        ),
                      },
                      {
                        title: capitalizeFirstLetter(t("entities:overwriteTo")),
                        render: (overwriteInstructions: Instruction) => (
                          <React.Fragment>
                            <Select
                              style={{ width: 180 }}
                              onChange={(value) =>
                                overwriteInstructions.id &&
                                setInstructionMapping(overwriteInstructions.id, value)
                              }
                              defaultValue={
                                state.mappedInstructions.find(
                                  (mappedInstruction) =>
                                    mappedInstruction.from === overwriteInstructions.id,
                                )?.to
                              }
                            >
                              {state.currentDocumentInstructions
                                .filter((di) => multiLanguageEmptyCheck(di))
                                .map(
                                  (instruction) =>
                                    instruction.id && (
                                      <Option value={instruction.id}>
                                        {instruction.caption["en"]}
                                      </Option>
                                    ),
                                )}
                            </Select>
                          </React.Fragment>
                        ),
                      },
                    ]}
                  />
                )}
              </div>
              <div className={styles["button-bar"]}>
                <Button
                  type="primary"
                  icon={<LeftOutlined />}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      currentStep: WizardSteps.ChooseLanguages,
                    }))
                  }
                >
                  {t("common:previous")}
                </Button>
                <Button
                  className={"button-next"}
                  type="primary"
                  icon={<RightOutlined />}
                  disabled={
                    state.writingType === "PARTIAL" &&
                    state.selectedDocumentInstructions.length !== state.mappedInstructions.length
                  }
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      currentStep: WizardSteps.Summary,
                    }))
                  }
                >
                  {t("common:next")}
                </Button>
              </div>
            </React.Fragment>
          ) : null}
          {state.currentStep === WizardSteps.Summary ? (
            <React.Fragment>
              <div className={styles["step-content"]}>
                <Title level={3}>{t("common:summary")}</Title>
                <div className={styles["step-text"]}>{t("texts:copyInstructionsStep5")}</div>
                <ul>
                  <li>
                    {t("common:xInstructions", {
                      number: state.selectedInstructionIds.length,
                    })}
                  </li>
                  <li>
                    {`${t("common:languages")}: `}
                    {state.selectedLanguages
                      .map((languageCode) => getLanguageTranslation(languageCode))
                      .join(", ")}
                  </li>
                  <li>{t(`texts:copyInstructionsSummary${state.writingType}`)}</li>
                </ul>
              </div>
              <div className={styles["button-bar"]}>
                <Button
                  type="primary"
                  icon={<LeftOutlined />}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      currentStep: WizardSteps.Options,
                    }))
                  }
                >
                  {t("common:previous")}
                </Button>
                <Button className={"button-next"} type="primary" onClick={importInstructions}>
                  {t("common:import")}
                </Button>
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};
