import { useQuery } from "@tanstack/react-query";
import { Group } from "../../models/templates/Group";
import api from "../../services/api";
import { CONST_SECURITY_FEATURES_GROUPS } from "../../utilities/constants/query-contants";

export const getSecurityFeatureGroups = (): Promise<Group[]> => {
  return api.get({
    path: CONST_SECURITY_FEATURES_GROUPS.plural,
  });
};

export const useQuerySecurityFeatureGroups = () => {
  return useQuery<Group[]>([CONST_SECURITY_FEATURES_GROUPS.plural], () =>
    getSecurityFeatureGroups(),
  );
};
