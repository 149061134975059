import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";
import { CONST_STATISTICS } from "../../utilities/constants/query-contants";
import { Statistics, StatisticsPublishingType } from "./statistics-type";

export const getStatistics = (publishingType: StatisticsPublishingType): Promise<Statistics[]> => {
  return api.get({
    path: `${CONST_STATISTICS.plural}/${publishingType}`,
  });
};

export const useQueryStatistics = (publishingType: StatisticsPublishingType) => {
  return useQuery<Statistics[]>([CONST_STATISTICS.plural], () => getStatistics(publishingType));
};
