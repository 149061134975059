import { TFunction } from "i18next";
import {
  Organization,
  OrganizationProductType,
  OrganizationType,
} from "../../models/reference_lists/Organization";
import {
  validateEmail,
  validateMaxChars,
  validateName,
  validatePhoneNumber,
  validateRequired,
  validateUrl,
} from "../Validators";

export const ValidateOrganization = (organization: Organization, t: TFunction) => {
  const errors: any = {};

  if (!organization.type || organization.type === OrganizationType.Producer) {
    errors.type = t("validations:organizationType");
  }

  if (!organization.productType || organization.productType === OrganizationProductType.Document) {
    errors.productType = t("validations:organizationProductType");
  }

  const nameResult =
    validateRequired(organization.name, t("properties:name")) ||
    validateName(organization.name, t("properties:name")) ||
    validateMaxChars(organization.name, 100, t("properties:name"));
  if (nameResult != null) {
    errors.name = nameResult;
  }

  const contactNameResult = validateMaxChars(
    organization.contact.name,
    150,
    t("properties:contactName"),
  );
  if (contactNameResult != null) {
    if (!errors.contact) {
      errors.contact = {};
    }
    errors.contact.name = contactNameResult;
  }

  const contactPhoneWorkResult = validatePhoneNumber(organization.contact.telephoneWork);
  if (contactPhoneWorkResult != null) {
    if (!errors.contact) {
      errors.contact = {};
    }
    errors.contact.telephoneWork = contactPhoneWorkResult;
  }

  const contactPhonePrivateResult = validatePhoneNumber(organization.contact.telephonePrivate);
  if (contactPhonePrivateResult != null) {
    if (!errors.contact) {
      errors.contact = {};
    }
    errors.contact.telephonePrivate = contactPhonePrivateResult;
  }

  const contactEmailResult = validateEmail(organization.contact.emailAddress);
  if (contactEmailResult != null) {
    if (!errors.contact) {
      errors.contact = {};
    }
    errors.contact.emailAddress = contactEmailResult;
  }

  const webAddressResult = validateUrl(organization.webAddress);
  if (webAddressResult != null) {
    errors.webAddress = webAddressResult;
  }

  const addressLine1Result = validateMaxChars(
    organization.address.addressLine1,
    250,
    t("properties:addressLine1"),
  );
  if (addressLine1Result != null) {
    if (!errors.address) {
      errors.address = {};
    }
    errors.address.addressLine1 = addressLine1Result;
  }

  const addressLine2Result = validateMaxChars(
    organization.address.addressLine2,
    250,
    t("properties:addressLine2"),
  );
  if (addressLine2Result != null) {
    if (!errors.address) {
      errors.address = {};
    }
    errors.address.addressLine2 = addressLine2Result;
  }

  const remarksResult = validateMaxChars(organization.remarks, 100, t("properties:remarks"));
  if (remarksResult != null) {
    errors.remarks = remarksResult;
  }

  return errors;
};

export const getTypeValues = (type: OrganizationType | OrganizationProductType) => {
  if (type) {
    return type
      .toString()
      .split(",")
      .map((type) => type.trim());
  }
};

export const setTypeValues = (keys: string[]) => keys.join(", ");
