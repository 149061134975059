import { TFunction } from "i18next";
import { Currency } from "../../models/reference_lists/Currency";
import { validateName, validateRegEx, validateRequired } from "../Validators";

export const ValidateCurrency = (currency: Currency, t: TFunction) => {
  const errors: any = {};

  const nameResult =
    validateRequired(currency.name, t("properties:name")) ||
    validateName(currency.name, t("properties:name"));
  if (nameResult != null) {
    errors.name = nameResult;
  }

  const isoCodeResult =
    validateRequired(currency.isoCode, t("properties:isoCode3")) ||
    validateRegEx(
      currency.isoCode,
      /^[a-zA-Z]{3}$/,
      t("validations:inputLettersX", {
        property: t("properties:isoCode3"),
        value: 3,
      }),
    );
  if (isoCodeResult != null) {
    errors.isoCode = isoCodeResult;
  }

  const isoNumberResult =
    validateRequired(currency.isoNumber, t("properties:isoNumber3")) ||
    validateRegEx(
      currency.isoNumber,
      /^\d{3}$/,
      t("validations:inputDigitsX", {
        property: t("properties:isoNumber3"),
        value: 3,
      }),
    );
  if (isoNumberResult != null) {
    errors.isoNumber = isoNumberResult;
  }

  return errors;
};
