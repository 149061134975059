import { EditOutlined } from "@ant-design/icons";
import { Button, Drawer, Layout } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { IListSchema } from "../../../models/InputComponentData";
import { Attribute, AttributeDataType } from "../../../models/templates/Attribute";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { CmsCreateButton } from "../../common/ButtonComponents";
import { GroupManager, GroupType } from "../../common/GroupManager";
import {
  CmsContent,
  CmsPageHeader,
  CmsSearch,
  CmsSider,
  CmsTable,
} from "../../common/PageComponents";
import { ITreeViewItem, TreeView } from "../../common/TreeView";
import { useDeleteAttribute } from "../../../queries/attributes/detail";
import { useQueryAttributeGroups } from "../../../queries/attributeGroups/lists";
import { useQueryAttributes } from "../../../queries/attributes/lists";
import { useCmsContext } from "../../../context/app/CmsContext";

export const AttributeList = () => {
  const { t } = useTranslation();
  const { canCreate, canUpdate, canDelete, canView } = useAuthorization("attribute");
  const { push } = useHistory();
  const context = useCmsContext();
  const [state, setState] = React.useState<{
    selectedGroup?: string;
    expandedGroups?: string[];
    groupsManagerVisible: boolean;
  }>({
    selectedGroup: sessionStorage.getItem("currentAttributeGroup") ?? undefined,
    expandedGroups: undefined,
    groupsManagerVisible: false,
  });

  const {
    data: attributes,
    isLoading: isLoadingAttributes,
    refetch: refetchAttributes,
  } = useQueryAttributes({ groupId: state.selectedGroup });
  const { mutateAsync: deleteAttribute, isLoading: isDeletingAttribute } = useDeleteAttribute();
  const {
    data: attributeGroups,
    isLoading: isLoadingAttributeGroups,
    refetch: refetchAttributeGroups,
  } = useQueryAttributeGroups();

  const [attributesFilter, setAttributesFilter] = React.useState<Attribute[]>(attributes ?? []);

  React.useEffect(() => {
    if (!!attributes) {
      setAttributesFilter(attributes);

      const sessionKeys = sessionStorage.getItem("attributeGroupExpandedKeys");
      const keys = sessionKeys ? sessionKeys.split(",") : [];
      setState((prevState) => ({
        ...prevState,
        attributes,
        expandedGroups: keys,
      }));
    }
  }, [attributes]);

  const search = (searchText) => {
    if (attributes) {
      const search = searchText.toLowerCase();
      const filteredAttributes = attributes.filter((attribute) => {
        const name = attribute.name && attribute.name.toLowerCase();
        return name && name.includes(search);
      });

      setAttributesFilter(filteredAttributes);
    }
  };

  React.useEffect(() => {
    if (state.selectedGroup) {
      sessionStorage.setItem("currentAttributeGroup", state.selectedGroup);
    }

    if (state.expandedGroups) {
      sessionStorage.setItem("attributeGroupExpandedKeys", state.expandedGroups.join(","));
    }
  }, [state.selectedGroup, state.expandedGroups]);

  React.useEffect(() => {
    if (attributesFilter) {
      context?.setDuplicateErrorMessage(t("errors:attributeLinked"));
    }
  }, [attributesFilter, t]);

  const isLoadingAny = isLoadingAttributes || isLoadingAttributeGroups || isDeletingAttribute;

  return (
    <React.Fragment>
      <CmsPageHeader
        title={t("entities:attributes")}
        extra={[
          <CmsSearch key="search" onChange={search} />,
          (canCreate || canUpdate) && (
            <Button
              key="manage"
              icon={<EditOutlined />}
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  groupsManagerVisible: true,
                }))
              }
            >
              {t("common:manageGroups")}
            </Button>
          ),
          canCreate && <CmsCreateButton key="create" onClick={() => push(`/attribute`)} />,
        ]}
      />
      <Layout>
        <CmsSider>
          <TreeView
            items={attributeGroups as ITreeViewItem[]}
            selectedKey={state.selectedGroup}
            expandedKeys={state.expandedGroups}
            onExpand={(keys) => setState((prevState) => ({ ...prevState, expandedGroups: keys }))}
            onSelect={(groupId) =>
              setState((prevState) => ({
                ...prevState,
                selectedGroup: groupId,
              }))
            }
          />
        </CmsSider>
        <CmsContent>
          <CmsTable
            loading={isLoadingAny}
            dataSource={attributesFilter}
            rowKey="id"
            columns={[
              { title: t("properties:name"), dataIndex: "name" },
              {
                title: t("properties:caption"),
                dataIndex: "caption",
                render: (caption) => getLocalizedValue(caption),
              },
              {
                title: t("properties:type"),
                dataIndex: "dataType",
                render: (dataType) => {
                  switch (dataType) {
                    case AttributeDataType.Country:
                      return t("entities:country");
                    case AttributeDataType.Province:
                      return t("entities:province");
                    case AttributeDataType.Currency:
                      return t("entities:currency");
                    case AttributeDataType.Text:
                      return t("properties:attributeTypeText");
                    case AttributeDataType.MultiLingualText:
                      return t("properties:attributeTypeMultilingualText");
                    case AttributeDataType.Number:
                      return t("properties:attributeTypeNumber");
                    case AttributeDataType.YesNo:
                      return t("properties:attributeTypeYesNo");
                    case AttributeDataType.DateTime:
                      return t("properties:attributeTypeDateTime");
                    case AttributeDataType.List:
                      return t("properties:attributeTypeList");
                    case AttributeDataType.Language:
                      return t("entities:language");
                    case AttributeDataType.Region:
                      return t("entities:region");
                    case AttributeDataType.Organization:
                      return t("entities:organization");
                    case AttributeDataType.Product:
                      return t("entities:product");
                    case AttributeDataType.DocumentType:
                      return t("entities:documentType");
                    case AttributeDataType.MaterialType:
                      return t("entities:materialType");
                    case AttributeDataType.Object:
                      return t("properties:attributeTypeObject");
                  }
                },
              },
              {
                title: t("properties:defaultValue"),
                dataIndex: "defaultValue",
                render: (defaultValue, record) => {
                  if (record.dataType === AttributeDataType.List) {
                    const devaultValue = (record.dataTypeSchema as IListSchema).items.find(
                      (item) => item.code === defaultValue,
                    );
                    return devaultValue && devaultValue.name && devaultValue.name.en;
                  }
                  return defaultValue;
                },
              },
            ]}
            onEdit={canUpdate || canView ? (id) => push(`/attribute/${id}`) : undefined}
            onDelete={
              canDelete
                ? async (id) => {
                    await deleteAttribute(id);
                    await refetchAttributes();
                  }
                : undefined
            }
          />
        </CmsContent>
      </Layout>

      <Drawer
        placement="left"
        open={state.groupsManagerVisible}
        width={600}
        onClose={async () => {
          await refetchAttributeGroups();
          await refetchAttributes();
          setState((prevState) => ({
            ...prevState,
            groupsManagerVisible: false,
          }));
        }}
        title={t("common:manageGroups")}
      >
        <GroupManager
          visible={state.groupsManagerVisible}
          groupType={GroupType.Attribute}
          onDataChanged={() => {}}
        />
      </Drawer>
    </React.Fragment>
  );
};
