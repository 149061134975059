import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";
import { Hologram } from "./types";
import { CONST_HOLOGRAMS } from "../../utilities/constants/query-contants";

export const getHologram = (id?: string): Promise<Hologram> => {
  return api.get({
    path: `${CONST_HOLOGRAMS.singular}/${id}`,
  });
};

export const useQueryHologram = (id?: string) => {
  return useQuery<Hologram>([CONST_HOLOGRAMS.singular, { id }], () => getHologram(id), {
    enabled: !!id,
  });
};
