import { TFunction } from "i18next";
import { DocumentType } from "../../models/reference_lists/DocumentType";
import { validateName, validateRegEx, validateRequired } from "../Validators";

export const ValidateDocumentType = (documentType: DocumentType, t: TFunction) => {
  const errors: any = {};

  const nameResult =
    validateRequired(documentType.name, t("properties:name")) ||
    validateName(documentType.name, t("properties:name"));
  if (nameResult != null) {
    errors.name = nameResult;
  }

  const codeResult =
    validateRequired(documentType.code, t("properties:code")) ||
    validateRegEx(documentType.code, /^[A-Z]{1,2}$/, t("validations:documentTypeCode"));
  if (codeResult != null) {
    errors.code = codeResult;
  }

  return errors;
};
