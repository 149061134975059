import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../../services/api";
import { CONST_SEARCH } from "../../utilities/constants/query-contants";
import { SearchQuery } from "../search-settings/types";
import blobToFile from "js-file-download";

export const postSearch = (
  payload: SearchQuery,
  headers?: Record<string, string>,
  isFile?: boolean,
): Promise<any> => {
  return api.post({
    path: CONST_SEARCH.singular,
    body: { ...payload },
    headers: { ...headers },
    isFile: isFile ?? false,
  });
};

export const useQuerySearch = (payload?: string) => {
  return useQuery<any>([CONST_SEARCH.singular, { payload }], () => postSearch({ query: payload }), {
    enabled: !!payload,
  });
};

export const useExportQuerySearch = () =>
  useMutation((payload: SearchQuery) => postSearch(payload, { Accept: "text/csv" }, true), {
    onSuccess: (data) => {
      blobToFile(data, "Search results.csv");
    },
  });
