import { Form, Input, Select, InputNumber } from "antd";
import { FormProps } from "antd/lib/form";
import React, { ReactNode } from "react";
import { MultilingualInput } from "./MultilingualInput";
import { MultilingualInputData } from "../../models/MultilingualInputData";

export const CmsFormLayout = {
  onecolumn: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 6 },
      lg: { span: 5 },
      xl: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 18 },
      lg: { span: 15 },
      xl: { span: 12 },
    },
  },
  twocolumn: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 8 },
      xl: { span: 10 },
      xxl: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 16 },
      lg: { span: 16 },
      xl: { span: 14 },
      xxl: { span: 16 },
    },
  },
  modal: {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 16,
    },
  },
  filter: {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 14,
    },
  },
};

export const CmsColLayout = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 24 },
  lg: { span: 24 },
  xl: { span: 12 },
  xxl: { span: 11 },
};

export const CmsForm: React.FunctionComponent<FormProps> = (props) => {
  return (
    <Form {...CmsFormLayout.onecolumn} {...props}>
      {props.children}
    </Form>
  );
};

export const CmsFilter: React.FunctionComponent<FormProps> = (props) => {
  return (
    <Form {...CmsFormLayout.filter} {...props}>
      {props.children}
    </Form>
  );
};

export const CmsModalForm: React.FunctionComponent<FormProps> = (props) => {
  return (
    <Form {...CmsFormLayout.modal} {...props}>
      {props.children}
    </Form>
  );
};

export interface CmsFormItemProps {
  label: string;
  info?: ReactNode;
  labelAlign?: "left" | "right";
  required?: boolean;
  error?: ReactNode;
}
export const CmsFormItem: React.FunctionComponent<CmsFormItemProps> = (props: any) => {
  const label = props.info ? (
    <>
      {props.info}&nbsp;{props.label}
    </>
  ) : (
    props.label
  );

  return (
    <Form.Item
      label={label}
      labelAlign={props.labelAlign}
      required={props.required}
      validateStatus={!props.error ? "success" : "error"}
      help={props.error}
    >
      {props.children}
    </Form.Item>
  );
};

export interface CmsTextProps extends CmsFormItemProps {
  id?: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  multiLine?: boolean;
  value?: string | number | string[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const CmsText: React.FunctionComponent<CmsTextProps> = (props) => {
  return (
    <CmsFormItem {...props}>
      {!props.multiLine ? (
        <Input
          id={props.id}
          key={props.label || props.id}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          disabled={props.disabled}
          maxLength={props.maxLength}
          value={props.value}
          onChange={props.onChange}
        />
      ) : (
        <Input.TextArea
          rows={5}
          id={props.id}
          key={props.label || props.id}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          disabled={props.disabled}
          maxLength={props.maxLength}
          value={props.value}
          onChange={props.onChange}
        />
      )}
    </CmsFormItem>
  );
};

export interface CmsMultilingualTextProps extends CmsFormItemProps {
  disabled?: boolean;
  readOnly?: boolean;
  value: MultilingualInputData;
  onChange?: (value: MultilingualInputData) => void;
}

export const CmsMultilingualText: React.FunctionComponent<CmsMultilingualTextProps> = (props) => {
  return (
    <CmsFormItem {...props}>
      <MultilingualInput
        disabled={props.disabled}
        readOnly={props.readOnly}
        mlData={props.value}
        onChange={props.onChange}
      />
    </CmsFormItem>
  );
};

export interface CmsSelectProps extends CmsFormItemProps {
  id?: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  loading?: boolean;
  value?: string;
  allowClear?: boolean;
  showSearch?: boolean;
  onChange?: (value: any) => void;
}

export const CmsSelect: React.FunctionComponent<CmsSelectProps> = (props) => {
  return (
    <CmsFormItem {...props}>
      <Select
        showSearch={props.showSearch ?? true}
        id={props.id}
        key={props.label || props.id}
        disabled={props.disabled || props.readOnly}
        loading={props.loading}
        value={props.value}
        placeholder={props.placeholder}
        filterOption={(input, option) =>
          option?.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        allowClear={props.allowClear ?? true}
        onChange={props.onChange}
      >
        {props.children}
      </Select>
    </CmsFormItem>
  );
};

export interface CmsNumberProps extends CmsFormItemProps {
  id?: string;
  readOnly?: boolean;
  disabled?: boolean;
  max?: number;
  min?: number;
  precision?: number;
  value?: number;
  placeholder?: string;
  onChange: (value: number | null) => void;
}

export const CmsNumber: React.FunctionComponent<CmsNumberProps> = (props) => {
  return (
    <CmsFormItem {...props}>
      <InputNumber
        id={props.id}
        readOnly={props.readOnly}
        disabled={props.disabled}
        max={props.max}
        min={props.min}
        precision={props.precision}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
    </CmsFormItem>
  );
};
