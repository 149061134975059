import { TFunction } from "i18next";
import { Field } from "../../models/templates/Field";
import { validateName, validateRegEx, validateRequired } from "../Validators";

export const ValidateField = (field: Field, t: TFunction) => {
  const errors: any = {};

  const groupIdResult = validateRequired(field.groupId, t("properties:group"));
  if (groupIdResult != null) {
    errors.groupId = groupIdResult;
  }

  const nameResult =
    validateRequired(field.name, t("properties:name")) ||
    validateName(field.name, t("properties:name"));
  if (nameResult != null) {
    errors.name = nameResult;
  }

  const codeResult =
    validateRequired(field.code, t("properties:code")) ||
    validateRegEx(
      field.code,
      /^[A-Z0-9_]*$/,
      t("validations:code", { property: t("properties:code") }),
    );
  if (codeResult != null) {
    errors.code = codeResult;
  }

  return errors;
};
