import { useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useCmsContext } from "../context/app/CmsContext";
import { QueryStringParams } from "../queries/document-series/document-series-type";

export type KeyedQueryFilters = {
  attributes?: QueryStringParams;
  query?: QueryStringParams;
};

export const useDocumentFilter = (): {
  changeFilterPanel: (visible: boolean) => void;
  saveFilters: (filter: KeyedQueryFilters) => void;
  restoreFilters: () => KeyedQueryFilters | undefined;
  resetFilters: () => void;
  disableBefore: (current: Dayjs, last?: string) => boolean;
  disabledAfter: (current: Dayjs, last?: string) => boolean;
} => {
  const context = useCmsContext();

  const changeFilterPanel = (visible: boolean) => {
    context?.setDocumentSeriesFilterPanel(visible);
    localStorage.setItem("setFilterDocumentSeriesPanel", visible.toString());
  };

  const saveFilters = (filter: KeyedQueryFilters): void =>
    sessionStorage.setItem("keyedQueryFilters", JSON.stringify(filter));

  const restoreFilters = (): KeyedQueryFilters | undefined => {
    const filters = sessionStorage.getItem("keyedQueryFilters");
    if (filters) {
      return JSON.parse(filters);
    }
  };

  const resetFilters = () => {
    sessionStorage.removeItem("keyedQueryFilters");
  };

  const disableBefore = (current: Dayjs, last?: string): boolean => {
    return (current && last && current < dayjs(last).endOf("day")) as boolean;
  };

  const disabledAfter = (current: Dayjs, last?: string): boolean => {
    return (current && last && current > dayjs(last).endOf("day")) as boolean;
  };

  useEffect(() => {
    const getFilterPanel = localStorage.getItem("setFilterDocumentSeriesPanel");
    if (getFilterPanel != null) {
      context?.setDocumentSeriesFilterPanel(JSON.parse(getFilterPanel) === true);
    }
  }, []);

  return {
    changeFilterPanel,
    saveFilters,
    restoreFilters,
    resetFilters,
    disableBefore,
    disabledAfter,
  };
};
