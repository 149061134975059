import { TFunction } from "i18next";
import { AttributeLink } from "../../models/templates/AttributeLink";
import { AttributeValue } from "../../models/data/AttributeValue";
import { AttributeKeyMap, getAttributeByKey } from "./AttributeConfig";
import { DocumentSeries } from "../../models/data/Document";
import { DOCUMENTTEMPLATE_NAMES } from "../Constants";

export const getDocumentListConfig = (
  isLoading: boolean,
  name: string,
  t: TFunction,
  attributeLinks?: AttributeLink[],
) => {
  const attributeSearch = (attributes?: AttributeValue[], attributeName?: AttributeKeyMap) => {
    if (!isLoading && attributeName && attributes && attributeLinks) {
      return getAttributeByKey(attributeName, attributes, attributeLinks)?.value;
    }
  };

  let config = [] as any[];

  switch (name) {
    case DOCUMENTTEMPLATE_NAMES.BANKNOTE:
    case DOCUMENTTEMPLATE_NAMES.COUNTERFEIT_BANKNOTE:
      config = [
        { title: t("properties:code"), dataIndex: "code" },
        {
          title: t("properties:denomination"),
          dataIndex: "attributeValues",
          render: (attributes) => attributeSearch(attributes, "denomination"),
        },
        {
          title: t("properties:inCirculation"),
          dataIndex: "attributeValues",
          render: (attributes) => {
            const value = attributeSearch(attributes, "inCirculation");

            if (!value) return;

            return t(
              `properties:inCirculationBanknote${attributeSearch(attributes, "inCirculation")}`,
            );
          },
        },
        {
          title: t("properties:series"),
          dataIndex: "attributeValues",
          render: (attributes) => attributeSearch(attributes, "seriesYear"),
        },
      ];
      break;
    case DOCUMENTTEMPLATE_NAMES.MULTI_SIDED:
    case DOCUMENTTEMPLATE_NAMES.DOUBLE_SIDED:
    default:
      config = [
        { title: t("properties:code"), dataIndex: "code" },
        {
          title: `${t("properties:ages")} / ${t("properties:status")}`,
          dataIndex: "",
          render: (document: DocumentSeries) =>
            attributeSearch(document.attributeValues, "ages") ??
            t(`properties:documentStatus${document.status}`),
        },
        {
          title: `${t("properties:documentCategory")} / ${t("properties:inCirculation")}`,
          dataIndex: "attributeValues",
          render: (attributes) => {
            return (
              attributeSearch(attributes, "documentCategory") ??
              attributeSearch(attributes, "inCirculation")
            );
          },
        },
        {
          title: `${t("properties:series")} / ${t("properties:dateInCirculation")}`,
          dataIndex: "attributeValues",
          render: (attributes) => {
            return (
              attributeSearch(attributes, "series") ??
              attributeSearch(attributes, "dateInCirculation")
            );
          },
        },
      ];
      break;
  }

  return config;
};
