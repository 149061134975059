import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input, Tag } from "antd";
import Text from "antd/lib/typography/Text";
import * as React from "react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useApi } from "../../hooks/useApi";
import "../../styles/search.css";

interface State {
  searchText: string;
  searchResults: SearchResult[] | null;
}

export const SearchBox = () => {
  const timer = useRef<NodeJS.Timer | null>(null);
  const { t } = useTranslation();
  const history = useHistory();

  const [state, setState] = React.useState<State>({
    searchText: "",
    searchResults: null,
  });

  const { getAll, isLoading } = useApi<SearchResult>(`search/simple`);

  useEffect(() => {
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      (async () => {
        if (state.searchText && state.searchText.length >= 3) {
          const searchResults = await getAll({
            path: state.searchText.replace("-", " "),
          });
          setState((prevState) => ({ ...prevState, searchResults }));
        } else {
          setState((prevState) => ({ ...prevState, searchResults: null }));
        }
      })();
    }, 500);
  }, [getAll, state.searchText]);

  const renderOptions = (results: SearchResult[] | null) => {
    if (results && results.length > 0) {
      return results.map((result) => ({
        ...result,
        value: result.name,
        key: `search_result_${result.id}`,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text ellipsis>{result.name}</Text>
            <span style={{ fontSize: "8", marginRight: 0 }}>
              <Tag color={"blue"} style={{ marginRight: 0 }}>
                {getFriendlyName(result.type)}
              </Tag>
            </span>
          </div>
        ),
      }));
    }

    if (results && state.searchText && state.searchText.length >= 3) {
      return [
        {
          id: "no-results",
          key: "no-results",
          type: "no-results",
          name: "no-results",
          label: (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {t("common:noResults")}
            </div>
          ),
          value: t("common:noResults"),
        },
      ];
    }

    return [];
  };

  const handleSearch = (value: string) => {
    setState((prevState) => ({ ...prevState, searchText: value }));
  };

  const getFriendlyName = (type: string) =>
    t(`entities:${type.charAt(0).toLowerCase() + type.slice(1)}`);

  const handleSelect = async (
    _: string,
    option: { id: string; type?: string; name?: string; extensionId?: string },
  ) => {
    if (option.type) {
      const type: string = option.type;
      const url = type
        .split(/(?=[A-Z])/)
        .join("-")
        .toLocaleLowerCase();

      const constructedUrl = option.extensionId ? `${option.extensionId}/${option.id}` : option.id;

      history.push(`/${url}/${constructedUrl}`);

      setState((prevState) => ({
        ...prevState,
        searchText: "",
        searchResults: null,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        searchText: state.searchText,
        searchResults: null,
      }));
    }
  };

  return (
    <div className="certain-category-search-wrapper" style={{ width: 400 }} key="search">
      <AutoComplete
        className="certain-category-search"
        dropdownClassName="certain-category-search-dropdown"
        dropdownStyle={{ width: 400 }}
        style={{ width: "100%" }}
        listHeight={500}
        value={state.searchText}
        options={renderOptions(state.searchResults)}
        onChange={handleSearch}
        onSelect={handleSelect}
        placeholder={t("common:search")}
      >
        <Input
          suffix={
            isLoading ? (
              <LoadingOutlined className="certain-category-icon" spin />
            ) : (
              <SearchOutlined className="certain-category-icon" />
            )
          }
        />
      </AutoComplete>
    </div>
  );
};

interface SearchResult {
  id: string;
  type: string;
  name: any;
  extensionId?: string;
}
