import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Province } from "../../../models/reference_lists/Province";
import { validateName, validateRequired } from "../../../utilities/Validators";
import { DetailsModal } from "../../data/documents/DetailsModal";
import { useApi } from "../../../hooks/useApi";
import { Currency } from "../../../models/reference_lists/Currency";

export const ProvinceDetailsModal = ({
  show,
  province,
  onClose,
  onSave,
}: {
  show: boolean;
  province: Province;
  onClose: () => void;
  onSave: (province: Province) => void;
}) => {
  const {
    data: currencies,
    getAll: getCurrencies,
    isLoading: isLoadingCurrencies,
  } = useApi<Currency>("currencies");

  useEffect(() => {
    (async () => {
      await getCurrencies();
    })();
  }, [getCurrencies]);

  const { t } = useTranslation();

  const validate = (province: Province) => {
    const errors: any = {};

    const nameCheck =
      validateRequired(province.name, t("properties:name")) ||
      validateName(province.name, t("properties:name"));

    if (nameCheck != null) {
      errors.name = nameCheck;
    }

    const isoCode2Check = validateRequired(province.isoCode2, t("properties:isoCode"));

    if (isoCode2Check != null) {
      errors.isoCode2 = isoCode2Check;
    }

    return errors;
  };

  if (province == null || isLoadingCurrencies) {
    return null;
  }

  return (
    <DetailsModal
      key={"provinceModal"}
      title="province"
      validate={validate}
      data={province}
      onClose={onClose}
      fields={[
        { name: "name", type: "INPUT" },
        { name: "isoCode2", type: "INPUT" },
        {
          name: "currencyIds",
          type: "TRANSFER",
          dataset: currencies.map((currency) => ({
            id: currency.id,
            caption: currency.name,
          })),
        },
      ]}
      onSave={onSave}
      show={show}
    />
  );
};
