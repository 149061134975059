import { Button, Layout, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Crop } from "react-image-crop";
import { Point } from "react-lasso-select/lib/helpers";
import { FieldValue } from "../../../models/data/FieldValue";
import { SelectionType } from "../../../models/data/SelectionType";
import { ConvertToPercentCrop } from "../../../utilities/CropHelper";
import { ConvertPolygon } from "../../../utilities/PolygonHelper";
import config from "../../../Config";
import styles from "./CopyInstructionsModal.module.scss";
import { CmsCancelButton, CmsSaveButton } from "../../common/ButtonComponents";
import RectangleCrop from "./SecurityFeatureSelectionCrop/RectangleCrop";
import LassoCrop from "./SecurityFeatureSelectionCrop/LassoCrop";
import { PlusOutlined } from "@ant-design/icons";
import { RowButtonSet } from "../../common/RowButtonSet";
import { useAuthorization } from "../../../hooks/useAuthorization";

const { Sider, Content } = Layout;

interface FieldProps {
  fieldLinkId: string;
  fieldName: string;
  fieldValues: FieldValue[];
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  shapeType: "RECTANGLE" | "POLYGON";
  points?: Point[];
}

interface Props {
  fileId: string;
  imageId: string;
  featureName: string;
  selection: FieldProps;
  onClose?(save?: boolean): void;
  onSave(): void;
  onChange(selection: FieldValue[]): void;
}

interface fieldStates {
  fieldValues: FieldValue[];
  selectedFieldValueIndex?: number;
}

export default function FieldValuesEditorModal(props: Props) {
  const { t } = useTranslation();
  const { canDelete, canCreate } = useAuthorization("field", "data");

  const initialSelection = () => {
    if (props.selection.fieldValues.length <= 0) {
      return;
    }

    switch (props.selection.fieldValues[0].shapeType) {
      case "RECTANGLE":
        return props.selection.fieldValues[0] as Crop;
      case "POLYGON":
        return props.selection.fieldValues[0].points as Point[];
      default:
        return;
    }
  };

  const [selectionType, setSelectionType] = useState<SelectionType>({
    type: props.selection.fieldValues[0]?.shapeType ?? null,
    selection: initialSelection(),
  });

  const [activeField, setActiveField] = useState<Crop | Point[] | undefined>(initialSelection());

  const [fieldStates, setFieldStates] = useState<fieldStates>({
    fieldValues: props.selection.fieldValues,
    selectedFieldValueIndex: props.selection.fieldValues.length > 0 ? 0 : undefined,
  });

  const [croppedImage, setCroppedImage] = useState<string | undefined>();

  const [imageSize, setImageSize] = useState<{
    width: number;
    height: number;
    natWidth: number;
    natHeight: number;
  }>({ width: 0, height: 0, natWidth: 0, natHeight: 0 });

  const createContext = (fieldValues: FieldValue[]) =>
    fieldValues.map((field) => {
      const compiledField = {
        ...field,
        fieldLinkId: props.selection.fieldLinkId,
        imageId: props.imageId,
      } as FieldValue;

      switch (field.shapeType) {
        case "RECTANGLE":
          const rectangle = {
            height: field.height,
            width: field.width,
            x: field.x,
            y: field.y,
          } as Crop;

          compiledField.height = rectangle.height;
          compiledField.width = rectangle.width;
          compiledField.y = rectangle.y;
          compiledField.x = rectangle.x;
          break;
        case "POLYGON":
          const polygon = field.points as Point[];
          compiledField.height = undefined;
          compiledField.width = undefined;
          compiledField.y = undefined;
          compiledField.x = undefined;
          compiledField.points = polygon;
          break;
        default:
          break;
      }
      return compiledField;
    }) as FieldValue[];

  const convertShape = () => {
    if (selectionType.selection === null || selectionType.selection === undefined) {
      return;
    }

    const fieldValues = JSON.parse(JSON.stringify(fieldStates.fieldValues));

    if (fieldStates.selectedFieldValueIndex === undefined) return;

    switch (selectionType.type) {
      case "RECTANGLE":
        const rectangle = selectionType.selection as Crop;
        const convert = ConvertToPercentCrop(imageSize.width, imageSize.height, rectangle);

        fieldValues[fieldStates.selectedFieldValueIndex].height = (convert.height ?? 0) / 100;
        fieldValues[fieldStates.selectedFieldValueIndex].width = (convert.width ?? 0) / 100;
        fieldValues[fieldStates.selectedFieldValueIndex].x = (convert.x ?? 0) / 100;
        fieldValues[fieldStates.selectedFieldValueIndex].y = (convert.y ?? 0) / 100;
        fieldValues[fieldStates.selectedFieldValueIndex].points = null;
        break;
      case "POLYGON":
        const polygon = selectionType.selection as Point[];
        fieldValues[fieldStates.selectedFieldValueIndex].height = null;
        fieldValues[fieldStates.selectedFieldValueIndex].width = null;
        fieldValues[fieldStates.selectedFieldValueIndex].x = null;
        fieldValues[fieldStates.selectedFieldValueIndex].y = null;
        fieldValues[fieldStates.selectedFieldValueIndex].points =
          ConvertPolygon(polygon, "RELATIVE", imageSize) ?? [];
        break;
      default:
        break;
    }

    fieldValues[fieldStates.selectedFieldValueIndex].shapeType = selectionType.type;

    setFieldStates((prevState) => ({
      ...prevState,
      fieldValues: fieldValues,
    }));
  };

  const handleCroppedImage = (croppedImage) => {
    setCroppedImage(croppedImage);
  };
  const handleImageSize = (imageSize) => {
    setImageSize(imageSize);
  };

  const handleSelection = (selection) => {
    setSelectionType((prevSelection) => ({
      ...prevSelection,
      selection: selection,
    }));
  };

  useEffect(() => {
    if (!croppedImage || !imageSize) return;
    convertShape();
    // eslint-disable-next-line
  }, [croppedImage]);

  useEffect(() => {
    saveContext();
    // eslint-disable-next-line
  }, [fieldStates]);

  const saveContext = () => {
    if (!croppedImage) {
      props.onChange([]);
      return;
    }

    const fieldValues = createContext(fieldStates.fieldValues);

    props.onChange(fieldValues);
  };

  const onSaveSelection = () => {
    saveContext();
    props.onSave();
  };

  const src = `${config.apiUrl}file/${props.fileId}?imageSize=XL`;

  return (
    <Modal
      width={(imageSize.width > 0 ? imageSize.width : 600) + 600}
      style={{ height: "750px", maxHeight: "80%" }}
      title={props.featureName}
      open={true}
      closable={true}
      onCancel={() => props.onClose!()}
      footer={[
        <CmsCancelButton key="cancel" onClick={() => props.onClose && props.onClose(false)} />,
        <CmsSaveButton key="save" onClick={() => onSaveSelection()} />,
      ]}
    >
      <Layout>
        <Sider
          width={"auto"}
          style={{
            overflow: "hidden",
            background: "#fff",
            paddingRight: "16px",
          }}
        >
          <div className={"image"}>
            {selectionType.type !== null && (
              <>
                {selectionType.type === "RECTANGLE" && (
                  <RectangleCrop
                    key={`rectangle-crop-${fieldStates.selectedFieldValueIndex}`}
                    src={src}
                    t={t}
                    onCroppedImage={handleCroppedImage}
                    onImageSize={handleImageSize}
                    onSelection={handleSelection}
                    preSelection={(activeField as Crop) ?? undefined}
                  />
                )}
                {selectionType.type === "POLYGON" && (
                  <LassoCrop
                    key={`lasso-crop-${fieldStates.selectedFieldValueIndex}`}
                    src={src}
                    t={t}
                    onCroppedImage={handleCroppedImage}
                    onImageSize={handleImageSize}
                    onSelection={handleSelection}
                    preSelection={(activeField as Point[]) ?? undefined}
                  />
                )}
              </>
            )}
          </div>
        </Sider>
        <Content style={{ background: "#fff", paddingLeft: "10px", minWidth: "350px" }}>
          <h3>{t("common:preview")}</h3>
          <img
            alt=""
            src={croppedImage ? croppedImage : "/assets/images/keesing-logo.jpg"}
            style={{
              objectFit: "contain",
              maxWidth: "100%",
              margin: "5px",
              maxHeight: "750px",
            }}
            crossOrigin={"anonymous"}
          />
          <Table
            bordered
            size={"small"}
            dataSource={fieldStates.fieldValues}
            rowKey={(row, index) => index as number}
            pagination={false}
            className={styles["selection-table"]}
            rowSelection={{
              selectedRowKeys: [fieldStates.selectedFieldValueIndex ?? -1],
            }}
            columns={[
              {
                title: t("properties:selection"),
                render: (text, record, index) => <div style={{ width: "100px" }}>#{index + 1}</div>,
              },
              {
                title: canCreate ? (
                  <div className="flex">
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      style={{ margin: "0px 2px" }}
                      size="small"
                      onClick={() => {
                        setSelectionType({
                          type: "RECTANGLE",
                          selection: null,
                        } as SelectionType);
                        setActiveField({
                          x: 0,
                          y: 0,
                          width: 0,
                          height: 0,
                        } as Crop);
                        const fieldValues = fieldStates.fieldValues.slice(0);
                        fieldValues.push(new FieldValue());
                        const selectedFieldValueIndex = fieldValues.length - 1;

                        setFieldStates((prevState) => ({
                          ...prevState,
                          fieldValues: fieldValues,
                          selectedFieldValueIndex: selectedFieldValueIndex,
                        }));
                      }}
                    >
                      Rectangle
                    </Button>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      style={{ margin: "0px 2px" }}
                      size="small"
                      onClick={() => {
                        setSelectionType({
                          type: "POLYGON",
                          selection: null,
                        } as SelectionType);
                        setActiveField(undefined);

                        const fieldValues = fieldStates.fieldValues.slice(0);
                        fieldValues.push({
                          shapeType: "POLYGON",
                          points: [],
                          width: undefined,
                          height: undefined,
                          fieldLinkId: undefined,
                          id: undefined,
                          imageId: undefined,
                          withFieldLink: undefined,
                        });
                        const selectedFieldValueIndex = fieldValues.length - 1;

                        setFieldStates((prevState) => ({
                          ...prevState,
                          fieldValues: fieldValues,
                          selectedFieldValueIndex: selectedFieldValueIndex,
                        }));
                      }}
                    >
                      Polygon
                    </Button>
                  </div>
                ) : undefined,
                dataIndex: "actions",
                align: "right",
                render: (text, record, index) => (
                  <RowButtonSet
                    onDelete={
                      canDelete
                        ? () => {
                            const fieldValues = fieldStates.fieldValues.slice(0);
                            fieldValues.splice(index, 1);

                            if (fieldValues.length === 0) {
                              setSelectionType({
                                selection: undefined,
                                type: null,
                              });
                              setCroppedImage(undefined);
                            }

                            if (fieldValues.length > 0) {
                              switch (fieldValues[0].shapeType) {
                                case "RECTANGLE":
                                  setSelectionType({
                                    type: "RECTANGLE",
                                    selection: {
                                      x: fieldValues[0].x,
                                      y: fieldValues[0].y,
                                      width: fieldValues[0].width,
                                      height: fieldValues[0].height,
                                    },
                                  } as SelectionType);

                                  setActiveField({
                                    unit: "px",
                                    x: fieldValues[0].x ?? 0,
                                    y: fieldValues[0].y ?? 0,
                                    width: fieldValues[0].width ?? 0,
                                    height: fieldValues[0].height ?? 0,
                                  });
                                  break;
                                case "POLYGON":
                                  setSelectionType({
                                    type: "POLYGON",
                                    selection: fieldValues[0].points,
                                  } as SelectionType);
                                  setActiveField(fieldValues[0].points);
                                  break;
                                default:
                                  break;
                              }
                            }

                            setFieldStates(() => ({
                              fieldValues: fieldValues,
                              selectedFieldValueIndex: fieldValues.length > 0 ? 0 : undefined,
                            }));
                          }
                        : undefined
                    }
                  />
                ),
              },
            ]}
            onRow={(record, index) => {
              return {
                onClick: () => {
                  const selectedFieldValues = fieldStates.fieldValues[index ?? -1];

                  setFieldStates((prevState) => ({
                    ...prevState,
                    selectedFieldValueIndex: index,
                  }));

                  if (selectedFieldValues.shapeType === "RECTANGLE") {
                    setSelectionType({
                      type: "RECTANGLE",
                      selection: {
                        x: selectedFieldValues.x,
                        y: selectedFieldValues.y,
                        width: selectedFieldValues.width,
                        height: selectedFieldValues.height,
                      },
                    } as SelectionType);

                    setActiveField({
                      x: selectedFieldValues.x ?? 0,
                      y: selectedFieldValues.y ?? 0,
                      width: selectedFieldValues.width ?? 0,
                      height: selectedFieldValues.height ?? 0,
                    } as Crop);
                  }

                  if (selectedFieldValues.shapeType === "POLYGON") {
                    setSelectionType({
                      type: "POLYGON",
                      selection:
                        selectedFieldValues.points && selectedFieldValues.points.length > 0
                          ? selectedFieldValues.points
                          : null,
                    } as SelectionType);
                    setActiveField(selectedFieldValues.points);
                  }
                },
              };
            }}
          />
        </Content>
      </Layout>
    </Modal>
  );
}
